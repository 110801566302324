import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/UserContext';
import QuickTopUpModal from '../payment/QuickTopUpModal';

const LowCreditsHandler = ({ 
  requiredCredits = 0, 
  children, 
  onCreditAdded = null,
  includeWarning = true,
  warningThreshold = 50,
  triggerRef = null
}) => {
  const { userData } = useContext(UserContext);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [hasLowCredits, setHasLowCredits] = useState(false);
  
  useEffect(() => {
    // Check if credits are below warning threshold
    if (includeWarning && userData.credits < warningThreshold) {
      setHasLowCredits(true);
    } else {
      setHasLowCredits(false);
    }
  }, [userData.credits, includeWarning, warningThreshold]);
  
  // Allow parent components to trigger the top-up modal
  useEffect(() => {
    if (triggerRef) {
      triggerRef.current = {
        showTopUpModal: () => setShowTopUpModal(true)
      };
    }
  }, [triggerRef]);
  
  const handleTopUpSuccess = (successful, creditsAdded) => {
    setShowTopUpModal(false);
    if (successful && onCreditAdded) {
      onCreditAdded(creditsAdded);
    }
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains('modal')) {
      setShowTopUpModal(false);
    }
  };
  
  return (
    <>
      {hasLowCredits && (
        <div className="low-credits-warning">
          <span className="warning-icon">⚠️</span>
          <div className="warning-text">
            <p>Low credit balance: {userData.credits} credits remaining</p>
            <small>You may not have enough credits for future operations</small>
          </div>
          <button 
            className="top-up-btn"
            onClick={() => setShowTopUpModal(true)}
          >
            Top Up Now
          </button>
        </div>
      )}
      
      {children}
      
      {showTopUpModal && (
        <QuickTopUpModal
          onClose={handleTopUpSuccess}
          onModalClose={handleModalClose}
          requiredCredits={requiredCredits}
        />
      )}
    </>
  );
};

export default LowCreditsHandler;