import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import BaseModal from './BaseModal';
import { smsService } from '../../services/api';
import { useUser } from '../../contexts/UserContext';

const HistoryModal = ({ onClose, onModalClose }) => {
  const { isAuthorized } = useUser();
  const [usageHistory, setUsageHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedMessage, setExpandedMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Use useCallback to memoize the loadTransactions function
  const loadTransactions = useCallback(async (page = currentPage, search = searchTerm) => {
    if (!isAuthorized) return;
    
    try {
      setLoading(true);
      const result = await smsService.getTransactions(page, 10, search);
      
      if (result.success) {
        // Convert to format used by our app
        const formattedHistory = result.data.map(tx => ({
          id: tx.id,
          date: tx.created_at.split(' ')[0],
          time: tx.created_at.split(' ')[1],
          amount: tx.type === 'payment' ? tx.amount : Math.abs(tx.amount),
          description: tx.description,
          sender: tx.sender || 'System',
          receiver: tx.receiver || 'N/A',
          message: tx.message || '',
          type: tx.type || 'sms'
        }));
        
        setUsageHistory(formattedHistory);
        setTotalPages(result.pagination.totalPages);
      }
    } catch (error) {
      console.error('Error loading transactions:', error);
      toast.error('Failed to load transaction history');
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm, isAuthorized]);

  // Load transactions on first render and when dependencies change
  useEffect(() => {
    if (isAuthorized) {
      loadTransactions(currentPage, searchTerm);
    }
  }, [isAuthorized, currentPage, searchTerm, loadTransactions]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // loadTransactions will be called automatically due to dependency
  };

  // Handle search
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
    // Debounce search to avoid too many requests
    clearTimeout(window.searchTimeout);
    window.searchTimeout = setTimeout(() => {
      loadTransactions(1, e.target.value);
    }, 500);
  };

  return (
    <BaseModal
      onClose={onClose}
      onModalClose={onModalClose}
      title="Usage History"
    >
      <div className="search-container">
        <input
          type="text"
          placeholder="Search history..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>
      
      {loading && <div className="loading-indicator">Loading...</div>}
      
      <table className="history-table">
        <thead>
          <tr>
            <th>Date & Time</th>
            <th>Description</th>
            <th>Sender</th>
            <th>Receiver</th>
            <th>Message</th>
            <th>Credits</th>
          </tr>
        </thead>
        <tbody>
          {usageHistory.map((item) => (
            <tr key={item.id}>
              <td>{item.date}<br/>{item.time}</td>
              <td>{item.description}</td>
              <td>{item.sender}</td>
              <td>
                {item.receiver.length > 20 
                  ? `${item.receiver.substring(0, 20)}...` 
                  : item.receiver}
              </td>
              <td>
                {item.type === 'payment' ? (
                  <div className="transaction-info">
                    <span className="transaction-label">Payment</span>
                  </div>
                ) : (
                  expandedMessage === item.id ? (
                    <div>
                      {item.message}
                      <button 
                        className="message-toggle" 
                        onClick={() => setExpandedMessage(null)}
                      >
                        Show Less
                      </button>
                    </div>
                  ) : (
                    <div>
                      {item.message.substring(0, 60)}
                      {item.message.length > 60 && '...'}
                      {item.message.length > 60 && (
                        <button 
                          className="message-toggle" 
                          onClick={() => setExpandedMessage(item.id)}
                        >
                          Show More
                        </button>
                      )}
                    </div>
                  )
                )}
              </td>
              <td className={item.amount < 0 ? 'credit' : 'debit'}>
                {item.amount < 0 ? '+' : '-'}{Math.abs(item.amount)}
              </td>
            </tr>
          ))}
          {usageHistory.length === 0 && !loading && (
            <tr>
              <td colSpan="6" style={{ textAlign: 'center', padding: '2rem' }}>
                No transaction history found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      
      {totalPages > 1 && (
        <div className="pagination">
          <button
            onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1 || loading}
            className="page-btn"
          >
            Previous
          </button>
          
          <span className="page-info">
            Page {currentPage} of {totalPages}
          </span>
          
          <button
            onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
            disabled={currentPage === totalPages || loading}
            className="page-btn"
          >
            Next
          </button>
        </div>
      )}
    </BaseModal>
  );
};

export default HistoryModal;