import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BaseModal from './BaseModal';
import { settingsService, authService } from '../../services/api';
import { useUser } from '../../contexts/UserContext';

const SettingsModal = ({ onClose, onModalClose, onEmailVerificationNeeded }) => {
  const { userData, updateUserData } = useUser();
  
  // State for form inputs
  const [company, setCompany] = useState(userData.company || {
    name: '',
    address: '',
    vatNumber: '',
    contactPerson: ''
  });
  const [email, setEmail] = useState(userData.email || '');
  const [authorizedEmailDomains, setAuthorizedEmailDomains] = useState(
    userData.authorizedEmailDomains && userData.authorizedEmailDomains.length 
      ? userData.authorizedEmailDomains 
      : ['']
  );
  const [loading, setLoading] = useState(false);

  // Handle settings save
  const handleSaveSettings = async () => {
    setLoading(true);
    
    try {
      // If email is changing, this will trigger verification request
      const result = await settingsService.saveSettings(
        company, 
        email, 
        // Filter out empty domains
        authorizedEmailDomains.filter(d => d.trim())
      );
      
      if (result.success) {
        if (result.message && result.message.includes('Verification code')) {
          // Email verification needed
          onEmailVerificationNeeded();
          toast.info('Please check your new email for verification code');
        } else {
          // No verification needed, just saved
          onClose();
          
          // Refresh user data
          const refreshedData = await authService.refreshUserData();
          if (refreshedData) {
            updateUserData(refreshedData);
          }
          
          toast.success('Settings saved successfully');
        }
      }
    } catch (error) {
      toast.error(`Error saving settings: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Email/domain validation
  const validateEmailOrDomain = (input) => {
    if (!input) return true; // Empty input is valid (will be filtered out)
    
    // Email regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Domain regex (with @ prefix)
    const domainRegex = /^@[^\s@]+\.[^\s@]+$/;
    
    return emailRegex.test(input) || domainRegex.test(input);
  };

  return (
    <BaseModal
      onClose={onClose}
      onModalClose={onModalClose}
      title="Account Settings"
      maxWidth="600px"
    >
      <div className="settings-form">
        {/*
        <div className="settings-section">
          <h3>Company Information</h3>
          
          <div className="form-group">
            <label htmlFor="company-name">Company Name:</label>
            <input
              type="text"
              id="company-name"
              value={company.name}
              onChange={(e) => setCompany({...company, name: e.target.value})}
              placeholder="Your Company Ltd."
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="company-address">Address:</label>
            <textarea
              id="company-address"
              value={company.address}
              onChange={(e) => setCompany({...company, address: e.target.value})}
              placeholder="Company address"
              rows="3"
            ></textarea>
          </div>
          
          <div className="form-group">
            <label htmlFor="company-vat">VAT Number:</label>
            <input
              type="text"
              id="company-vat"
              value={company.vatNumber}
              onChange={(e) => setCompany({...company, vatNumber: e.target.value})}
              placeholder="VAT123456789"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="contact-person">Contact Person:</label>
            <input
              type="text"
              id="contact-person"
              value={company.contactPerson}
              onChange={(e) => setCompany({...company, contactPerson: e.target.value})}
              placeholder="John Doe"
            />
          </div>
        </div>
        */}
        
        <div className="settings-section">
          <h3>Contact Information</h3>
          
          <div className="form-group">
            <label htmlFor="settings-phone">Phone Number:</label>
            <input
              type="text"
              id="settings-phone"
              value={userData.phoneNumber}
              readOnly
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="settings-email">Email:</label>
            <input
              type="email"
              id="settings-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        
        <div className="settings-section">
          <h3>Authorized Email-to-SMS Senders</h3>
          <p>Email addresses or domains (@example.com) that can send SMS via email-to-SMS feature</p>
          
          {authorizedEmailDomains.map((emailOrDomain, index) => (
            <div className="email-input" key={index}>
              <div className="form-group" style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  value={emailOrDomain}
                  onChange={(e) => {
                    const newDomains = [...authorizedEmailDomains];
                    newDomains[index] = e.target.value;
                    setAuthorizedEmailDomains(newDomains);
                  }}
                  placeholder="user@example.com or @example.com"
                  className={emailOrDomain && !validateEmailOrDomain(emailOrDomain) ? "invalid-input" : ""}
                  style={{ flex: 1, marginRight: "8px" }}
                />
                <button
                  type="button"
                  className="remove-domain-btn"
                  onClick={() => {
                    const newDomains = authorizedEmailDomains.filter((_, i) => i !== index);
                    if (newDomains.length === 0) newDomains.push('');
                    setAuthorizedEmailDomains(newDomains);
                  }}
                  disabled={authorizedEmailDomains.length === 1 && !authorizedEmailDomains[0]}
                  style={{ 
                    width: "30px",
                    height: "30px", 
                    flexShrink: 0,
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  &times;
                </button>
              </div>
              {emailOrDomain && !validateEmailOrDomain(emailOrDomain) && (
                <div className="input-error">Please enter a valid email address or domain (with @ prefix)</div>
              )}
            </div>
          ))}
          
          <button
            type="button"
            className="add-domain-btn"
            onClick={() => setAuthorizedEmailDomains([...authorizedEmailDomains, ''])}
          >
            Add Email or Domain
          </button>
        </div>
        
        <button 
          className="settings-save-btn" 
          onClick={handleSaveSettings}
          disabled={loading || authorizedEmailDomains.some(entry => entry && !validateEmailOrDomain(entry))}
        >
          {loading ? 'Saving...' : 'Save Settings'}
        </button>
      </div>
    </BaseModal>
  );
};

export default SettingsModal;