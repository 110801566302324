import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { paymentService } from '../../services/api';
import { calculateCreditBonus } from '../../utils/helpers';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Inter", "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#e74c3c',
      iconColor: '#e74c3c'
    }
  }
};

const StripeCheckout = ({ 
  amount, 
  userId, 
  setLoading, 
  returnUrl, 
  disabled,
  savedPaymentMethod = null,
  onPaymentSuccess = null,
  forceCredits = null, // Add parameter to force specific credit amount for special offers
  saveCardForced = false, // Add parameter to force saving the card
  enableAutoTopUpOnSuccess = true // New parameter to control auto top-up behavior
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [saveCardDetails, setSaveCardDetails] = useState(saveCardForced || true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe) {
      // Stripe.js has not loaded yet
      return;
    }

    setIsProcessing(true);
    setLoading(true);
    setErrorMessage(null);

    try {
      // Create a payment intent on the server with setup_future_usage flag
      const result = await paymentService.createPaymentIntent(
        userId, 
        amount, 
        savedPaymentMethod ? savedPaymentMethod.id : null,
        saveCardForced || saveCardDetails // Always send true if saveCardForced is true
      );
      
      if (!result.success) {
        throw new Error(result.error || "Failed to create payment intent");
      }

      let paymentResult;

      // If using a saved payment method
      if (savedPaymentMethod) {
        paymentResult = await stripe.confirmCardPayment(
          result.client_secret,
          {
            payment_method: savedPaymentMethod.id,
            return_url: returnUrl,
          }
        );
      } else {
        // Using a new card
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          throw new Error("Card element not found");
        }

        // Create confirmation parameters with proper setup_future_usage
        const confirmParams = {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: 'ToneSMS User',
            }
          },
          return_url: returnUrl
        };

        // Add setup_future_usage if saving the card
        if (saveCardForced || saveCardDetails) {
          confirmParams.setup_future_usage = 'off_session';
        }

        // Confirm the payment
        paymentResult = await stripe.confirmCardPayment(
          result.client_secret,
          confirmParams
        );
      }

      if (paymentResult.error) {
        // Show error to the customer
        setErrorMessage(paymentResult.error.message || "An unexpected error occurred");
        toast.error(`Payment failed: ${paymentResult.error.message}`);
      } else if (paymentResult.paymentIntent.status === 'succeeded') {
        // The payment succeeded immediately
        const statusResult = await paymentService.checkPaymentStatus(paymentResult.paymentIntent.id);
        
        if (statusResult.success) {
          // If auto top-up should be enabled and this was a new card (not saved)
          if (enableAutoTopUpOnSuccess && (saveCardForced || saveCardDetails) && !savedPaymentMethod) {
            try {
              // Get the payment method ID from the result
              if (paymentResult.paymentIntent && paymentResult.paymentIntent.payment_method) {
                // Enable auto top-up with this payment method
                await paymentService.toggleAutoTopUp(
                  userId,
                  true, // Enable auto top-up
                  amount > 5 ? amount : 10, // Use payment amount or default to 10
                  paymentResult.paymentIntent.payment_method
                );
                
                console.log('Auto top-up enabled with new payment method');
                // We don't show a toast here to avoid overwhelming the user
              }
            } catch (autoTopUpError) {
              console.error('Error enabling auto top-up:', autoTopUpError);
              // We don't show an error to the user since the payment was successful
            }
          }
          
          // Don't show a toast here, as the parent component will handle it
          console.log(`Payment completed successfully. Added ${statusResult.credits_added} credits.`);
          
          if (onPaymentSuccess) {
            onPaymentSuccess(statusResult);
          } else {
            // Default behavior - reload page to refresh user data
            window.location.reload();
          }
        }
      } else if (paymentResult.paymentIntent.status === 'requires_action') {
        // The payment requires additional actions, like 3D Secure authentication
        toast.info('Redirecting to complete your payment...');
      }
    } catch (error) {
      console.error("Payment error:", error);
      setErrorMessage(error.message || "Payment processing failed");
      toast.error(`Payment error: ${error.message}`);
    } finally {
      setLoading(false);
      setIsProcessing(false);
    }
  };

  // Calculate the number of credits with any applicable bonus, or use forced credits if provided
  const { credits, bonus } = forceCredits ? { credits: forceCredits, bonus: 0 } : calculateCreditBonus(amount);

  return (
    <div className="stripe-checkout">
      <div className="credit-preview">
        <p>You will receive: <strong>{credits} credits</strong>
          {bonus > 0 && <span className="bonus-text"> (includes {bonus}% bonus)</span>}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="stripe-form">
        {!savedPaymentMethod && (
          <div className="form-group">
            <label htmlFor="card-element">Credit or Debit Card</label>
            <div className="card-element-container">
              <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
            </div>
            
            {!saveCardForced && (
              <div className="save-card-option">
                <input
                  type="checkbox"
                  id="save-card"
                  checked={saveCardDetails}
                  onChange={() => setSaveCardDetails(!saveCardDetails)}
                />
                <label htmlFor="save-card">Save card for future payments</label>
              </div>
            )}
            
            {saveCardForced && (
              <div className="save-card-option">
                <input
                  type="checkbox"
                  id="save-card"
                  checked={true}
                  disabled={true}
                />
                <label htmlFor="save-card">Your card will be saved for future payments</label>
              </div>
            )}
          </div>
        )}
        
        {savedPaymentMethod && (
          <div className="form-group">
            <label>Using Saved Card</label>
            <div className="saved-card-info">
              <span className="card-brand">
                {savedPaymentMethod.card.brand.charAt(0).toUpperCase() + savedPaymentMethod.card.brand.slice(1)}
              </span>
              <span className="card-last4">•••• {savedPaymentMethod.card.last4}</span>
              <span className="card-expiry">
                Expires {savedPaymentMethod.card.exp_month}/{savedPaymentMethod.card.exp_year.toString().substr(-2)}
              </span>
            </div>
          </div>
        )}
        
        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
        
        <button 
          type="submit" 
          className="pay-btn" 
          disabled={!stripe || isProcessing || disabled}
        >
          {isProcessing ? 'Processing...' : `Pay $${amount}`}
        </button>
      </form>
      
      <div className="secure-badge">
        <span className="lock-icon">🔒</span> Secure payment via Stripe
      </div>
    </div>
  );
};

export default StripeCheckout;