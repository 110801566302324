// Helper utilities for the ToneSMS application

/**
 * Detects the most likely delimiter in a CSV file content
 * Supports common delimiters: comma, semicolon, tab, pipe, and colon
 * 
 * @param {string} csvContent - The content of the CSV file as a string
 * @param {number} sampleLines - Number of lines to analyze (default: 5)
 * @returns {string} - The detected delimiter character
 */
export const detectCsvDelimiter = (csvContent, sampleLines = 5) => {
  if (!csvContent || typeof csvContent !== 'string') {
    return ','; // Default to comma if no content
  }
  
  // Common delimiters to check
  const delimiters = [
    { char: ',', name: 'comma' },
    { char: ';', name: 'semicolon' },
    { char: '\t', name: 'tab' },
    { char: '|', name: 'pipe' },
    { char: ':', name: 'colon' }
  ];
  
  // Get the first few lines of the file
  const lines = csvContent.split(/\r?\n/).filter(line => line.trim());
  const linesToCheck = lines.slice(0, Math.min(sampleLines, lines.length));
  
  if (linesToCheck.length === 0) {
    return ','; // Default to comma for empty content
  }
  
  // Count occurrences of each delimiter in the sample
  const scores = delimiters.map(delimiter => {
    let totalCount = 0;
    let consistencyScore = 0;
    
    // Count occurrences in each line
    const countsByLine = linesToCheck.map(line => {
      // Avoid counting delimiters within quoted fields
      let inQuotes = false;
      let count = 0;
      
      for (let i = 0; i < line.length; i++) {
        if (line[i] === '"' && (i === 0 || line[i-1] !== '\\')) {
          inQuotes = !inQuotes;
        } else if (line[i] === delimiter.char && !inQuotes) {
          count++;
        }
      }
      
      return count;
    });
    
    // Calculate total count
    totalCount = countsByLine.reduce((sum, count) => sum + count, 0);
    
    // Calculate consistency score (how consistent is the number of delimiters across lines)
    if (countsByLine.length > 1) {
      const average = totalCount / countsByLine.length;
      const variance = countsByLine.reduce((sum, count) => sum + Math.pow(count - average, 2), 0) / countsByLine.length;
      
      // Higher consistency = lower variance relative to the count
      consistencyScore = variance === 0 ? 
        (totalCount > 0 ? 100 : 0) : // Perfect consistency
        (totalCount / Math.sqrt(variance));
    } else {
      consistencyScore = totalCount;
    }
    
    return {
      delimiter,
      totalCount,
      consistencyScore
    };
  });
  
  // Sort by consistency score (higher is better)
  scores.sort((a, b) => b.consistencyScore - a.consistencyScore);
  
  // If the best delimiter has a reasonable score, use it
  if (scores[0].totalCount > 0) {
    return scores[0].delimiter.char;
  }
  
  // Default to comma if no clear winner
  return ',';
};

/**
 * Sanitizes CSV headers by removing empty columns and ensuring unique names
 * @param {Array<string>} headers - Raw CSV headers
 * @returns {Array<string>} - Cleaned headers
 */
export const sanitizeCsvHeaders = (headers) => {
  // Filter out empty header names
  let cleanedHeaders = headers.filter(header => header.trim() !== '');
  
  // If there are duplicate headers, make them unique
  const headerCounts = {};
  
  cleanedHeaders = cleanedHeaders.map(header => {
    const trimmedHeader = header.trim();
    
    // Skip truly empty headers after trimming
    if (trimmedHeader === '') {
      return '';
    }
    
    // Count occurrences of each header
    if (!headerCounts[trimmedHeader]) {
      headerCounts[trimmedHeader] = 1;
      return trimmedHeader;
    } else {
      headerCounts[trimmedHeader]++;
      return `${trimmedHeader}_${headerCounts[trimmedHeader]}`;
    }
  });
  
  // Final filter to remove any remaining empty headers
  return cleanedHeaders.filter(header => header !== '');
};

/**
 * Comprehensive phone number normalization function that handles various formats,
 * extensions, special characters, and edge cases.
 *
 * @param {string|number} phoneNumber - The phone number to normalize
 * @returns {string} - The normalized phone number without + prefix
 */
export const normalizePhoneNumber = (phoneNumber) => {
  // Handle null, undefined, empty values
  if (phoneNumber === null || phoneNumber === undefined || phoneNumber === '') {
    return '';
  }
  // Convert to string in case we get a number type
  phoneNumber = String(phoneNumber);
  // First handle the case if there are multiple phone numbers (comma or semicolon separated)
  if (phoneNumber.includes(',') || phoneNumber.includes(';')) {
    const numbers = phoneNumber.split(/[,;]/).map(num => normalizePhoneNumber(num.trim()));
    return numbers.filter(Boolean).join(',');
  }
  // Extract phone number if it's embedded in text
  // This regex looks for patterns that resemble phone numbers
  const phonePattern = /(?:\+?\d[\d\s()\-.\[\]]{7,})/;
  const match = phoneNumber.match(phonePattern);
  if (match && match[0].length < phoneNumber.length) {
    // If we found a phone number pattern within text, normalize just that part
    return normalizePhoneNumber(match[0]);
  }
  // Remove any extension information
  let numberWithoutExt = phoneNumber;
  const extPatterns = [
    /\s*ext\.?\s*\d+/i,
    /\s*extension\s*\d+/i,
    /\s*#\s*\d+/,
    /\s*x\d+/i
  ];
  extPatterns.forEach(pattern => {
    numberWithoutExt = numberWithoutExt.replace(pattern, '');
  });
  // Check if it's an international number (starting with +)
  const isInternational = numberWithoutExt.trim().startsWith('+');
  // Remove all non-digit characters
  let normalized = numberWithoutExt.replace(/[^\d]/g, '');
  
  // Remove the line that adds the + back for international numbers
  // if (isInternational) {
  //   normalized = '+' + normalized;
  // }
  
  // Basic validation:
  // 1. Handle empty result
  if (normalized === '' || normalized === '+') {
    return '';
  }
  // 2. Handle short phone numbers and short codes (3-6 digits)
  if (normalized.length < 7) {
    // For international numbers, just return as is (without +)
    if (isInternational) {
      return normalized;
    }
    // For non-international numbers, check if the original was mostly digits (likely a short code)
    const digitPercentage = (phoneNumber.replace(/[^\d]/g, '').length / phoneNumber.trim().length) * 100;
    if (digitPercentage >= 80 && normalized.length >= 3) {
      return normalized; // Likely a valid short code
    }
    return ''; // Too short to be a standard phone number
  }
  // 3. Handle invalid formats with misplaced + signs
  if (!isInternational && phoneNumber.includes('+')) {
    // Look for a + that's not at the start after trimming
    if (phoneNumber.trim().indexOf('+') > 0) {
      // If the + appears to be a separator (like in "555+123+4567"), try to extract the full number
      const cleanedNumber = phoneNumber.replace(/\+/g, '');
      if (cleanedNumber.length >= 7 && /^\d+$/.test(cleanedNumber)) {
        return cleanedNumber;
      }
      return ''; // Invalid format
    }
  }
  return normalized;
};
  
  /**
   * Handler for submitting forms with Enter key
   * @param {Event} event - The keydown event
   * @param {Function} submitFunction - The function to call on Enter
   */
  export const handleEnterKeySubmit = (event, submitFunction) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Only submit if enter is pressed without shift (to allow newlines in textarea)
      if (event.target.tagName !== 'TEXTAREA') {
        event.preventDefault();
        submitFunction();
      }
    }
  };
  
  /**
   * Calculate message properties for SMS
   * @param {string} text - The message text
   * @returns {object} Object with message properties
   */
  export const calculateMessageProperties = (text) => {
    // Check for special characters
    const hasSpecialChars = /[\u0080-\uFFFF]/.test(text);
    
    // Standard SMS is 160 characters, but with special chars it's 70
    const singleMessageLimit = hasSpecialChars ? 70 : 160;
    const multiMessageLimit = hasSpecialChars ? 67 : 153; // Slightly less for concatenated messages
    
    const messageCount = text.length <= singleMessageLimit 
      ? 1 
      : Math.ceil(text.length / multiMessageLimit);
      
    return {
      length: text.length,
      hasSpecialChars,
      singleMessageLimit,
      multiMessageLimit,
      messageCount
    };
  };
  
  /**
   * Calculate credit bonus based on payment amount
   * @param {number} amount - Payment amount in dollars
   * @returns {object} Object with credits and bonus percentage
   */
  export const calculateCreditBonus = (amount) => {
    // Basic conversion: $0.1 per credit
    const baseCredits = Math.floor(amount / 0.1);
    
    // Define bonus tiers
    let bonusPercentage = 0;
    
    if (amount >= 500) {
      bonusPercentage = 10; // 10% bonus for $500+
    } else if (amount >= 300) {
      bonusPercentage = 5;  // 5% bonus for $300-$499
    } else if (amount >= 100) {
      bonusPercentage = 2;  // 2% bonus for $100-$299
    }
    
    // Calculate bonus credits
    const bonusCredits = Math.floor(baseCredits * (bonusPercentage / 100));
    const totalCredits = baseCredits + bonusCredits;
    
    return {
      credits: totalCredits,
      bonus: bonusPercentage
    };
  };