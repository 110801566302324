import React from 'react';

/**
 * Component for displaying and using CSV column placeholders
 */
const CSVPlaceholders = ({ csvColumns, onInsertPlaceholder }) => {
  return (
    <div className="placeholders-container">
      <div className="placeholders-label">
        Insert personalization from CSV:
      </div>
      <div className="placeholders-list">
        {csvColumns.map((column, index) => (
          <button
            key={index}
            type="button"
            className="placeholder-btn"
            onClick={() => onInsertPlaceholder(`{{${column}}}`)}
          >
            {`{{${column}}}`}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CSVPlaceholders;