import React, { useState, useEffect } from 'react';
import { FaGift, FaArrowRight, FaTimes } from 'react-icons/fa';

const PromotionBanner = ({ onClose, onClick }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  // Calculate time remaining for the promotion (24 hours from first visit)
  useEffect(() => {
    // Check if we have a stored end time
    let endTime = localStorage.getItem('promo_end_time');
    
    // If not, set it to 24 hours from now
    if (!endTime) {
      endTime = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString();
      localStorage.setItem('promo_end_time', endTime);
    }
    
    // Update the countdown every second
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const end = new Date(endTime).getTime();
      const difference = end - now;
      
      // If the promotion has expired, remove it
      if (difference <= 0) {
        clearInterval(interval);
        onClose();
        localStorage.removeItem('promo_end_time');
        return;
      }
      
      // Calculate hours, minutes, and seconds
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      
      setTimeRemaining({ hours, minutes, seconds });
    }, 1000);
    
    // Clean up the interval
    return () => clearInterval(interval);
  }, [onClose]);
  
  // Format the time with leading zeros
  const formatTime = (value) => {
    return value.toString().padStart(2, '0');
  };
  
  return (
    <div className="promotion-banner">
      <div className="promotion-content">
        <div className="promotion-icon">
          <FaGift size={24} />
        </div>
        <div className="promotion-text">
          <h3>Special Offer for New Users!</h3>
          <p>Register now to get <strong>5 free credits</strong> to send your first SMS!</p>
          <p className="promotion-bonus">Plus, get <strong>50 credits for only $2</strong> when you register in:</p>
          <div className="countdown-timer">
            <div className="countdown-segment">
              <span className="countdown-value">{formatTime(timeRemaining.hours)}</span>
              <span className="countdown-label">hours</span>
            </div>
            <div className="countdown-separator">:</div>
            <div className="countdown-segment">
              <span className="countdown-value">{formatTime(timeRemaining.minutes)}</span>
              <span className="countdown-label">mins</span>
            </div>
            <div className="countdown-separator">:</div>
            <div className="countdown-segment">
              <span className="countdown-value">{formatTime(timeRemaining.seconds)}</span>
              <span className="countdown-label">secs</span>
            </div>
          </div>
        </div>
        <button className="promotion-button" onClick={onClick}>
          <span>Register Now</span>
          <FaArrowRight />
        </button>
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default PromotionBanner;