import React from 'react';
import BaseModal from './BaseModal';
import Contact from '../../pages/Contact';

const ContactModal = ({ onClose, onModalClose }) => {
  return (
    <BaseModal
      onClose={onClose}
      onModalClose={onModalClose}
    >
      <Contact />
    </BaseModal>
  );
};

export default ContactModal;