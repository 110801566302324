// api.js - API service for ToneSMS frontend

import { normalizePhoneNumber } from '../utils/helpers';
import referralService from './referralService'; // Import the referral service

// Get the base API URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.tonesms.com';

// Log the API URL during development
if (process.env.NODE_ENV === 'development') {
  console.log(`API configured with base URL: ${API_BASE_URL}`);
}

// Store authentication token in localStorage
const getToken = () => localStorage.getItem('token');
const setToken = (token) => localStorage.setItem('token', token);
const getApiKey = () => localStorage.getItem('apiKey');
const setApiKey = (apiKey) => localStorage.setItem('apiKey', apiKey);

// Helper function for API requests
const apiRequest = async (endpoint, method = 'GET', data = null, useToken = true) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  // Add authorization header if token exists and useToken is true
  if (useToken && getToken()) {
    headers['Authorization'] = `Bearer ${getToken()}`;
  }

  const config = {
    method,
    headers,
  };

  if (data) {
    config.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, config);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error || 'API request failed');
    }

    return result;
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
};

// Default placeholders that are always available
const defaultPlaceholders = [
  { name: 'current_date', 
    getValue: () => new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) },
  { name: 'short_date', 
    getValue: () => new Date().toLocaleDateString('en-US') },
  { name: 'time', 
    getValue: () => new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }) },
  { name: 'day', 
    getValue: () => new Date().toLocaleDateString('en-US', { weekday: 'long' }) },
  { name: 'month', 
    getValue: () => new Date().toLocaleDateString('en-US', { month: 'long' }) },
  { name: 'year', 
    getValue: () => new Date().getFullYear().toString() }
];

// Function to replace placeholders with actual data
const replacePlaceholders = (text, contactData = {}) => {
  if (!text) return text;
  
  let result = text;
  
  // Find all {{placeholders}}
  const placeholderRegex = /{{([^{}]+)}}/g;
  const matches = [...text.matchAll(placeholderRegex)];
  
  matches.forEach(match => {
    const placeholder = match[0]; // {{column_name}}
    const columnName = match[1]; // column_name
    
    // First check if it's a contact data field
    if (contactData[columnName] !== undefined) {
      result = result.replace(placeholder, contactData[columnName]);
    } 
    // Then check if it's a default placeholder
    else {
      const defaultPlaceholder = defaultPlaceholders.find(p => p.name === columnName);
      if (defaultPlaceholder) {
        result = result.replace(placeholder, defaultPlaceholder.getValue());
      }
    }
  });
  
  return result;
};

// Authentication Services
const authService = {
  // Logout function
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('userData');
    localStorage.removeItem('smsForm');
    return true;
  },

  checkSession: async () => {
    // Check if token exists
    const token = getToken();
    if (!token) return false;
    
    try {
      // Verify token with backend
      const response = await apiRequest('/auth/verify-token');
      
      if (response.success) {
        // Token is valid, refresh user data
        await authService.refreshUserData();
        return true;
      } else {
        // Token is invalid or expired, clean up
        authService.logout();
        return false;
      }
    } catch (error) {
      console.error('Session verification error:', error);
      return false;
    }
  },
  
  refreshUserData: async () => {
    try {
      const response = await apiRequest('/user/profile');
      if (response.success) {
        // Update localStorage with fresh data
        localStorage.setItem('userData', JSON.stringify({
          id: response.id,
          credits: response.credits,
          email: response.email,
          phone: response.phone,
          company: response.company,
          authorizedEmailDomains: response.authorized_email_domains
        }));
        return response;
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
    return null;
  },

  // Register new user
  register: async (phoneNumber, email) => {
    // Normalize phone number before sending to API
    const normalizedPhone = normalizePhoneNumber(phoneNumber);
    return apiRequest('/auth/register', 'POST', { phone_number: normalizedPhone, email }, false);
  },

  // Request OTP for login
  login: async (phoneNumber) => {
    // Normalize phone number before sending to API
    const normalizedPhone = normalizePhoneNumber(phoneNumber);
    return apiRequest('/auth/login', 'POST', { phone_number: normalizedPhone }, false);
  },

  // Email change verification functions
  requestEmailChange: async (newEmail) => {
    return apiRequest('/user/request-email-change', 'POST', { new_email: newEmail });
  },
  
  verifyEmailChange: async (verificationCode) => {
    return apiRequest('/user/verify-email-change', 'POST', { verification_code: verificationCode });
  },

  // Verify OTP and complete authentication
  verifyOTP: async (phoneNumber, otp) => {
    // Normalize phone number before sending to API
    const normalizedPhone = normalizePhoneNumber(phoneNumber);
    const result = await apiRequest('/auth/verify', 'POST', { phone_number: normalizedPhone, otp }, false);
    
    if (result.success) {
      // Store token and API key in localStorage
      setToken(result.token);
      setApiKey(result.api_key);
      
      // Store user data
      localStorage.setItem('userData', JSON.stringify({
        id: result.id,
        credits: result.credits,
        email: result.email,
        phone: result.phone,
        company: result.company,
        authorizedEmailDomains: result.authorized_email_domains
      }));
    }
    
    return result;
  },

  // Check if user is authenticated
  isAuthenticated: () => {
    return !!getToken();
  },

  // Get user data from localStorage
  getUserData: () => {
    const userData = localStorage.getItem('userData');
    try {
      return userData ? JSON.parse(userData) : null;
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  }
};

// SMS Services
const smsService = {
  // Unified SMS sending method (handles both regular and personalized SMS)
  sendSMS: async (sender, receivers, message, csvData = null, phoneColumn = null) => {
    // Base request data
    const requestData = {
      sender,
      receivers,
      message
    };
    
    // If CSV data is provided, add contact_data for personalization
    if (csvData && phoneColumn) {
      // Create a mapping of phone numbers to contact data
      const contactMap = {};
      
      // Process each contact and normalize phone numbers
      csvData.forEach(contact => {
        const phone = contact[phoneColumn];
        if (phone) {
          const normalizedPhone = normalizePhoneNumber(phone);
          contactMap[normalizedPhone] = contact;
        }
      });
      
      // Add contact data to request if we have any contacts
      if (Object.keys(contactMap).length > 0) {
        requestData.contact_data = contactMap;
      }
    }
    
    // Send the request to the unified endpoint
    return apiRequest('/send', 'POST', requestData);
  },

  // Get transaction history
  getTransactions: async (page = 1, limit = 10, search = '') => {
    return apiRequest(`/user/transactions?page=${page}&limit=${limit}&search=${encodeURIComponent(search || '')}`);
  }
};

// API Key Management
const apiKeyService = {
  // Regenerate API key
  regenerateApiKey: async () => {
    const result = await apiRequest('/apikey/regenerate', 'POST');
    
    if (result.success) {
      // Update stored API key
      setApiKey(result.api_key);
    }
    
    return result;
  },
  
  // Get the current API key from localStorage
  getApiKey: () => {
    return getApiKey();
  }
};

// User Settings
const settingsService = {
  // Save user settings
  saveSettings: async (company, email, authorizedEmailDomains) => {
    // If email is being changed, request verification first
    const userData = authService.getUserData();
    
    if (userData && email !== userData.email) {
      return authService.requestEmailChange(email);
    }
    
    // Otherwise proceed with normal settings update
    const result = await apiRequest('/user/settings', 'POST', {
      company,
      email,
      authorized_email_domains: authorizedEmailDomains
    });
    
    if (result.success) {
      // Update local storage data
      const userData = authService.getUserData();
      if (userData) {
        userData.email = email;
        userData.company = company;
        userData.authorizedEmailDomains = authorizedEmailDomains;
        
        localStorage.setItem('userData', JSON.stringify(userData));
      }
    }
    
    return result;
  }
};

// Payment service with enhanced saved payment methods support
const paymentService = {
  // Create a payment intent
  createPaymentIntent: async (userId, amount, paymentMethodId = null, setupFutureUsage = false) => {
    return apiRequest('/payment/create-intent', 'POST', {
      user_id: userId,
      amount,
      payment_method_id: paymentMethodId,
      setup_future_usage: setupFutureUsage
    });
  },
  
  // Check the status of a payment
  checkPaymentStatus: async (paymentIntentId) => {
    return apiRequest(`/payment/check-status/${paymentIntentId}`, 'GET');
  },

  // Get saved payment methods
  getSavedPaymentMethods: async (userId) => {
    return apiRequest(`/payment/methods/${userId}`, 'GET');
  },

  // Delete a saved payment method
  deleteSavedPaymentMethod: async (paymentMethodId) => {
    return apiRequest('/payment/methods/delete', 'POST', {
      payment_method_id: paymentMethodId
    });
  },

  // Set a payment method as default
  setDefaultPaymentMethod: async (userId, paymentMethodId) => {
    return apiRequest('/payment/methods/set-default', 'POST', {
      user_id: userId,
      payment_method_id: paymentMethodId
    });
  },

  // Toggle auto top-up setting
  toggleAutoTopUp: async (userId, enabled, amount, paymentMethodId) => {
    return apiRequest('/payment/auto-topup', 'POST', {
      user_id: userId,
      enabled,
      amount,
      payment_method_id: paymentMethodId
    });
  }
};

// Contact Service
const contactService = {
  sendContactMessage: async (name, email, message) => {
    return apiRequest('/contact', 'POST', {
      name,
      email,
      message
    }, false); // No token needed for contact form
  }
};

// Export all services
export {
  authService,
  smsService,
  apiKeyService,
  settingsService,
  paymentService,
  contactService,
  referralService,
  normalizePhoneNumber,
  replacePlaceholders
};