import React from 'react';
import { toast } from 'react-toastify';
import { normalizePhoneNumber, detectCsvDelimiter, sanitizeCsvHeaders } from '../../utils/helpers';

/**
 * Component for recipient phone numbers input with CSV upload support
 */
const RecipientInput = ({ 
  receivers,
  setReceivers,
  csvData,
  setCsvData,
  setCsvColumns,
  setShowCSVModal,
  file,
  setFile,
  setPhoneColumnIndex,
  setPreviewContact
}) => {
  
  // Parse CSV file and extract data
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    setFile(file);
    
    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      
      // Automatically detect the delimiter
      const delimiter = detectCsvDelimiter(content);
      
      // Parse CSV using the detected delimiter with proper handling of quoted fields
      const lines = content.split(/\r?\n/).filter(line => line.trim());
      if (lines.length === 0) {
        toast.error('The file appears to be empty or invalid');
        return;
      }
      
      // Extract headers (first line) and sanitize them
      const rawHeaders = lines[0].split(delimiter).map(header => header.trim());
      const headers = sanitizeCsvHeaders(rawHeaders);
      
      if (headers.length === 0) {
        toast.error('No valid column headers found in the CSV');
        return;
      }
      
      if (headers.length <= 1) {
        toast.error('Could not detect CSV columns. Please check the file format.');
        return;
      }
      
      setCsvColumns(headers);
      
      // Extract data rows with proper handling of quoted fields
      const rows = [];
      for (let i = 1; i < lines.length; i++) {
        if (lines[i].trim() === '') continue;
        
        // Handle quoted fields properly
        const cells = [];
        let currentCell = '';
        let inQuotes = false;
        
        for (let j = 0; j < lines[i].length; j++) {
          const char = lines[i][j];
          
          if (char === '"' && (j === 0 || lines[i][j-1] !== '\\')) {
            inQuotes = !inQuotes;
          } else if (char === delimiter && !inQuotes) {
            cells.push(currentCell.trim());
            currentCell = '';
          } else {
            currentCell += char;
          }
        }
        
        // Add the last cell
        cells.push(currentCell.trim());
        
        // Create a row object with the correct number of cells
        const row = {};
        headers.forEach((header, index) => {
          if (index < cells.length) {
            // Remove any surrounding quotes from the cell value
            const cellValue = cells[index];
            row[header] = cellValue.startsWith('"') && cellValue.endsWith('"') 
              ? cellValue.substring(1, cellValue.length - 1) 
              : cellValue;
          } else {
            // Handle case where a row might have fewer cells than headers
            row[header] = '';
          }
        });
        
        rows.push(row);
      }
      
      if (rows.length === 0) {
        toast.error('No valid data rows found in the CSV');
        return;
      }
      
      // Pre-process phone numbers in all columns to ensure they're properly formatted
      // This will help with extraction later
      rows.forEach(row => {
        headers.forEach(header => {
          if (row[header] && typeof row[header] === 'string' && 
              /^[\d\s\+\-\(\)\.]+$/.test(row[header])) {
            // This looks like a phone number, normalize it
            row[`_normalized_${header}`] = normalizePhoneNumber(row[header]);
          }
        });
      });
      
      setCsvData(rows);
      
      // Show modal to select phone column
      setShowCSVModal(true);
      
      // Set preview contact to first row
      if (rows.length > 0) {
        setPreviewContact(rows[0]);
      }
      
      // Notify the user about the detected delimiter
      const delimiterNames = {
        ',': 'comma',
        ';': 'semicolon',
        '\t': 'tab',
        '|': 'pipe',
        ':': 'colon'
      };
      
      toast.info(`Detected ${delimiterNames[delimiter] || delimiter} as the CSV delimiter`);
    };
    
    reader.onerror = () => {
      toast.error('Error reading the file');
    };
    
    reader.readAsText(file);
  };
  
  // Clear CSV data
  const clearCsvData = () => {
    setCsvData(null);
    setCsvColumns([]);
    setPhoneColumnIndex(-1);
    setReceivers('');
    setFile(null);
    setPreviewContact(null);
  };
  
  // Count recipients
  const recipientCount = receivers.split(/[\n,]/).filter(r => r.trim()).length;
  
  return (
    <div className="form-group">
      <label htmlFor="receivers">Recipient Numbers:</label>
      <textarea
        id="receivers"
        value={receivers}
        onChange={(e) => setReceivers(e.target.value)}
        placeholder="Enter phone numbers (comma or newline separated)"
        rows="4"
        readOnly={csvData !== null} // Make readonly if CSV is loaded
      ></textarea>
      
      <div className="upload-container">
        <label className="upload-btn">
          Upload CSV
          <input
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </label>
        {file && <span className="file-name">{file.name}</span>}
        <small className="upload-note">Upload a CSV file with contact data including phone numbers</small>
      </div>
      
      {csvData && (
        <div className="csv-info">
          <div className="csv-badge">
            <span>CSV Loaded: {csvData.length} contacts</span>
            <button 
              type="button" 
              className="clear-csv-btn"
              onClick={clearCsvData}
            >
              Clear
            </button>
          </div>
        </div>
      )}
      
      <small>
        {recipientCount} recipient(s)
      </small>
    </div>
  );
};

export default RecipientInput;