import React from 'react';

/**
 * Base modal component with improved structure for all modals in the application
 */
const BaseModal = ({ onClose, onModalClose, title, children, maxWidth }) => {
  return (
    <div className="modal" onClick={onModalClose}>
      <div className="modal-content" style={{ maxWidth: maxWidth || '800px' }} onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{title}</h2>
          <button className="close-btn" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default BaseModal;