import React from 'react';
import { replacePlaceholders } from './utils/placeholderUtils';
import { normalizePhoneNumber } from '../../utils/helpers';

/**
 * Component that shows a mobile phone preview of the SMS message
 */
const MessagePreview = ({ 
  sender,
  message,
  csvData,
  csvColumns,
  phoneColumnIndex,
  previewContact,
  setPreviewContact
}) => {
  // Get initials for the contact avatar
  const getInitials = (name) => {
    if (!name) return '?';
    
    const nameParts = name.trim().split(' ');
    if (nameParts.length >= 2) {
      return (nameParts[0][0] + nameParts[1][0]).toUpperCase();
    } else if (nameParts.length === 1 && nameParts[0].length >= 2) {
      // For single words, use first and second letter
      return (nameParts[0][0] + nameParts[0][1]).toUpperCase();
    } else if (nameParts.length === 1 && nameParts[0].length === 1) {
      return nameParts[0][0].toUpperCase();
    } else {
      return '?';
    }
  };

  // Get current time for the phone preview
  const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${hours}:${minutes} ${ampm}`;
  };
  
  // Format current time for message timestamp
  const getMessageTime = () => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${hours}:${minutes} ${ampm}`;
  };
  
  return (
    <div className="preview-container">
      <h3>Message Preview</h3>
      
      {/* Contact Selector (when CSV is loaded) */}
      {csvData && csvData.length > 0 && (
        <div className="preview-controls">
          <label htmlFor="contact-select">Preview for contact:</label>
          <select 
            id="contact-select" 
            className="contact-select"
            onChange={(e) => {
              const index = parseInt(e.target.value);
              setPreviewContact(csvData[index]);
            }}
          >
            {csvData.map((contact, index) => {
              const phoneColumn = csvColumns[phoneColumnIndex];
              return (
                <option key={index} value={index}>
                  {normalizePhoneNumber(contact[phoneColumn])} {contact.first_name ? `(${contact.first_name})` : ''}
                </option>
              );
            })}
          </select>
        </div>
      )}
      
      {/* Android Phone Preview */}
      <div className="phone">
        <div className="phone-notch"></div>
        <div className="phone-camera"></div>
        <div className="phone-screen">
          <div className="status-bar">
            <div className="status-bar-left">{getCurrentTime()}</div>
            <div className="status-bar-right">
              <div className="signal-icon">
                <div className="signal-bar"></div>
                <div className="signal-bar"></div>
                <div className="signal-bar"></div>
                <div className="signal-bar"></div>
              </div>
              {/* WiFi icon removed to save space */}
              <div className="battery-icon"></div>
            </div>
          </div>
          
          <div className="header">
            <div className="back-arrow"></div>
            <div className="contact-avatar">
              {getInitials(sender || 'ToneSMS')}
            </div>
            <div className="contact-info">
              <div className="contact-name">
                {sender || 'ToneSMS'}
              </div>
              <div className="contact-number">
                SMS
              </div>
            </div>
            <div className="action-buttons">
              <div className="action-button">
                <div className="call-icon"></div>
              </div>
              <div className="action-button">
                <div className="more-icon">
                  <div className="more-dot"></div>
                  <div className="more-dot"></div>
                  <div className="more-dot"></div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="chat-area">
            <div className="message-bubble message-incoming">
              <div>
                {message 
                  ? replacePlaceholders(message, previewContact)
                  : "Type your message to see how it will appear to recipients. Include {{placeholders}} to see personalized content."}
              </div>
              <div className="message-time">{getMessageTime()}</div>
            </div>
          </div>
          
          <div className="input-area">
            <div className="input-emoji">
              <div className="emoji-icon"></div>
            </div>
            <div className="input-field">
              <div className="input-text">Message</div>
            </div>
            <div className="send-button">
              <div className="send-icon"></div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Personalization explanation */}
      <div className="personalization-help">
        <h4>About Personalization</h4>
        <p>Use <code>{"{{placeholder}}"}</code> in your message to insert dynamic content.</p>
        
        {csvData && csvColumns.length > 0 && (
          <>
            <p>Available CSV fields:</p>
            <div className="available-fields">
              {csvColumns.map((column, index) => (
                <span key={index} className="field-tag">{column}</span>
              ))}
            </div>
          </>
        )}
        
        <p>Available dynamic fields:</p>
        <div className="available-fields">
          {['current_date', 'short_date', 'time', 'day', 'month', 'year'].map((placeholder, index) => (
            <span key={index} className="field-tag">{placeholder}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MessagePreview;