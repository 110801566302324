import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import BaseModal from './BaseModal';
import { authService } from '../../services/api';
import referralService from '../../services/referralService';
import { handleEnterKeySubmit } from '../../utils/helpers';
import Select from 'react-select';
import countryData from 'country-telephone-data';
import validator from 'validator';
import clm from 'country-locale-map';

const { allCountries } = countryData;

// Format countries for react-select
const countryOptions = allCountries.map(country => ({
  value: country.iso2,
  label: `${country.name} (${country.dialCode})`,
  dialCode: country.dialCode,
  flag: country.iso2.toUpperCase()
}));

const AuthModal = ({ onClose, onModalClose, onLoginSuccess, initialTab = 'login' }) => {
  const [authStep, setAuthStep] = useState(initialTab); // 'login' or 'otp' or 'register'
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [userIpInfo, setUserIpInfo] = useState(null);
  const [referralCode, setReferralCode] = useState(''); // For referral code
  const [showReferralInput, setShowReferralInput] = useState(false); // Control visibility of referral input
  
  // Refs for focus management
  const phoneInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const otpInputRef = useRef(null);
  const referralInputRef = useRef(null);

  // Check for stored referral code or URL parameter
  useEffect(() => {
    // Check URL for referral code
    const queryParams = new URLSearchParams(window.location.search);
    const urlRefCode = queryParams.get('ref');
    
    // Check localStorage for stored code
    const storedRefCode = localStorage.getItem('referral_code');
    
    // If we have a code from either source, use it and show the input
    if (urlRefCode || storedRefCode) {
      setReferralCode(urlRefCode || storedRefCode);
      setShowReferralInput(true);
      
      // If we're on the registration step and there's a referral code,
      // we should focus on the email input instead of the referral input
      if (authStep === 'register' && emailInputRef.current) {
        setTimeout(() => {
          emailInputRef.current.focus();
        }, 100);
      }
    }
  }, [authStep]);

  // Get user country from IP only once when component mounts and no saved preference exists
  useEffect(() => {
    const savedCountry = localStorage.getItem('userSelectedCountry');
    
    if (savedCountry) {
      try {
        // Use the saved country preference if available
        const countryData = JSON.parse(savedCountry);
        setSelectedCountry(countryData);
        return; // Skip IP detection if we have a saved preference
      } catch (error) {
        // Continue with IP detection if parsing fails
      }
    }
    
    const detectUserCountry = async () => {
      try {
        // Call a service to get the user's IP info
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setUserIpInfo(data);
        
        // Find and set the default country based on IP
        const countryCode = data.country_code.toLowerCase();
        const defaultCountry = countryOptions.find(country => country.value === countryCode);
        if (defaultCountry) {
          setSelectedCountry(defaultCountry);
        } else {
          // Fallback to US if country not found
          setSelectedCountry(countryOptions.find(country => country.value === 'us'));
        }
      } catch (error) {
        console.error('Error detecting user country:', error);
        // Fallback to US
        setSelectedCountry(countryOptions.find(country => country.value === 'us'));
      }
    };

    detectUserCountry();
  }, []);

  // Handle focus management when auth step changes
  useEffect(() => {
    // Set focus when auth step changes
    setTimeout(() => {
      if (authStep === 'login' && phoneInputRef.current) {
        phoneInputRef.current.focus();
      } else if (authStep === 'register' && emailInputRef.current) {
        emailInputRef.current.focus();
      } else if (authStep === 'otp' && otpInputRef.current) {
        otpInputRef.current.focus();
      }
    }, 100); // Small timeout to ensure the DOM has updated
  }, [authStep]);

  // Auto-detect country from phone number when input changes (including paste)
  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    
    // Skip processing if input is empty
    if (!input) {
      setPhoneNumber('');
      return;
    }
    
    // Handle international format - try all possible country code lengths
    if (input.includes('+')) {
      // Most country codes are between 1-3 digits, but some can be 4
      for (let codeLength = 1; codeLength <= 4; codeLength++) {
        // Skip if the input isn't long enough to have a valid country code of this length
        if (input.length <= codeLength + 1) continue;
        
        // Extract potential country code
        const potentialCode = input.substring(1, codeLength + 1);
        
        // Look for a matching country
        const matchingCountry = countryOptions.find(country => {
          const cleanDialCode = country.dialCode.replace(/\+/g, '');
          return cleanDialCode === potentialCode;
        });
        
        // If found a match
        if (matchingCountry) {
          setSelectedCountry(matchingCountry);
          
          // Get everything after the country code (the actual phone number)
          const numberPart = input.substring(codeLength + 1).trim();
          
          // Update the phone field with just the number part
          setPhoneNumber(numberPart);
          return; // Exit the function since we've handled this case
        }
      }
    }
    
    // If not international format or no match found, just update the input as is
    setPhoneNumber(input);
  };
  
  // Handle pasted or autocompleted input
  const handlePhonePaste = (e) => {
    // Store a reference to the target element
    const targetElement = e.target;
    
    // We need to use setTimeout because the onChange event fires before the paste completes
    setTimeout(() => {
      if (targetElement) {
        const input = targetElement.value;
        
        // Use the enhanced phone number handler for all paste operations
        handlePhoneNumberChange({ target: { value: input } });
      }
    }, 10); // Slightly longer timeout to ensure browser has processed the paste
  };
  
  // Add event listener for input to handle autofilled values
  const handlePhoneInput = (e) => {
    const input = e.target.value;
    
    // Process all input events to catch browser autofill
    if (input && input !== phoneNumber) {
      handlePhoneNumberChange({ target: { value: input } });
    }
  };

  // Format phone number with country code
  const getFullPhoneNumber = () => {
    if (!phoneNumber) return '';
    
    // Remove leading zeros from phone number
    const trimmedPhone = phoneNumber.replace(/^0+/, '');
    
    // Get the dial code without the + symbol
    const dialCodeWithoutPlus = selectedCountry?.dialCode.replace(/\+/g, '') || '';
    
    // Combine the dial code and the trimmed phone number
    return `+${dialCodeWithoutPlus}${trimmedPhone}`;
  };
  
  const validatePhoneNumber = (number) => {
    if (!number) return false;
    
    // Get the country code from the selected country
    const iso2Code = selectedCountry?.value;
    
    // If we have a country code, get the locale from country-locale-map
    // Otherwise use 'any' to validate against all locales
    let locale = 'any';
    
    if (iso2Code) {
      // Get locale for the selected country (will return undefined if not found)
      const countryLocale = clm.getLocaleByAlpha2(iso2Code);
      
      // If we got a locale, convert underscores to hyphens for validator.js
      if (countryLocale) {
        locale = countryLocale.replace('_', '-');
      }
    }
    
    // Use validator's isMobilePhone function with the correct locale
    try {
      if (validator.isMobilePhone(number, locale)) {
        return number.replace(/^\+/, '');
      }
      
      // If validation with specific locale fails, try with 'any'
      if (locale !== 'any' && validator.isMobilePhone(number, 'any')) {
        return number.replace(/^\+/, '');
      }
    } catch (error) {
      // Fallback to 'any' if there's an error with the locale
      if (validator.isMobilePhone(number, 'any')) {
        return number.replace(/^\+/, '');
      }
    }
    
    return false;
  };

  // Toggle referral code input visibility
  const toggleReferralInput = () => {
    setShowReferralInput(!showReferralInput);
    
    // Focus on the input when it becomes visible
    if (!showReferralInput) {
      setTimeout(() => {
        if (referralInputRef.current) {
          referralInputRef.current.focus();
        }
      }, 100);
    }
  };

  // Handle login/registration
  const handleAuth = async () => {
    setLoading(true);
    
    try {
      const fullPhoneNumber = getFullPhoneNumber();
      
      if (!fullPhoneNumber) {
        toast.error('Please enter your phone number');
        setLoading(false);
        return;
      }
      
      // Validate the phone number
      const validatedNumber = validatePhoneNumber(fullPhoneNumber);
      if (!validatedNumber) {
        toast.error(`Please enter a valid phone number for ${selectedCountry?.label || 'your country'}`);
        setLoading(false);
        return;
      }
      
      // Use the validated phone number for all operations
      const normalizedPhoneNumber = "+" + validatedNumber;
      
      if (authStep === 'login') {
        try {
          const result = await authService.login(normalizedPhoneNumber);
          
          if (result.success) {
            setAuthStep('otp');
            toast.info('OTP sent to your phone and email');
          }
        } catch (error) {
          // Check if error indicates user not found
          if (error.message && error.message.includes('User not found')) {
            // Switch to register view if user doesn't exist
            toast.info('Phone number not registered. Please create an account.');
            setAuthStep('register');
            // Focus on email input after switching to register screen
            setTimeout(() => {
              if (emailInputRef.current) {
                emailInputRef.current.focus();
              }
            }, 100);
          } else {
            // Handle other errors
            toast.error(`Login error: ${error.message}`);
          }
          setLoading(false);
          return;
        }
      } else if (authStep === 'otp') {
        if (!otp) {
          toast.error('Please enter the OTP sent to your phone');
          setLoading(false);
          return;
        }
        
        const result = await authService.verifyOTP(normalizedPhoneNumber, otp);
        
        if (result.success) {
          // If there was a referral code used during registration, apply it
          if (referralCode && (showReferralInput || localStorage.getItem('referral_code'))) {
            try {
              await referralService.applyReferralCode(referralCode, result.id);
              // Store the code to later complete the referral when a payment is made
              localStorage.setItem('referred_by', referralCode);
              // Remove the referral_code since it's been applied
              localStorage.removeItem('referral_code');
            } catch (referralError) {
              console.error('Error applying referral code:', referralError);
              // Continue with login even if referral application fails
            }
          }
          
          // Call the login success callback with the user data
          onLoginSuccess(result);
          toast.success('Login successful!');
        }
      } else if (authStep === 'register') {
        if (!normalizedPhoneNumber || !email) {
          toast.error('Please fill all required fields');
          setLoading(false);
          return;
        }
        
        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          toast.error('Please enter a valid email address');
          setLoading(false);
          return;
        }
        
        // If referral code is visible and filled, store it for later use
        if ((showReferralInput && referralCode) || localStorage.getItem('referral_code')) {
          localStorage.setItem('referral_code', referralCode || localStorage.getItem('referral_code'));
        }
        
        const result = await authService.register(normalizedPhoneNumber, email);
        
        if (result.success) {
          setAuthStep('otp');
          toast.info('OTP sent to your phone');
        }
      }
    } catch (error) {
      toast.error(`Authentication error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Custom styles for react-select
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: 'var(--medium-gray)',
      borderRadius: 'var(--border-radius)',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'var(--primary-color)'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'var(--primary-color)' : state.isFocused ? 'var(--light-gray)' : 'white',
      color: state.isSelected ? 'white' : 'var(--text-color)',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center'
    })
  };
  
  // Save country selection when it changes
  useEffect(() => {
    if (selectedCountry) {
      localStorage.setItem('userSelectedCountry', JSON.stringify(selectedCountry));
    }
  }, [selectedCountry]);

  // Custom Option component with flags
  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px 12px' }}>
      <span style={{ marginRight: '8px' }}>
        {data.flag && 
          <img 
            src={`https://flagcdn.com/16x12/${data.value}.png`} 
            alt={data.label}
            style={{ width: '16px', height: '12px' }}
          />
        }
      </span>
      {label}
    </div>
  );

  // Custom formatted value with flag
  const CustomSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '8px' }}>
        {data.flag && 
          <img 
            src={`https://flagcdn.com/16x12/${data.value}.png`} 
            alt={data.label}
            style={{ width: '16px', height: '12px' }}
          />
        }
      </span>
      {data.label}
    </div>
  );

  // Enhanced select styles for consistent appearance
  const enhancedSelectStyles = {
    ...customSelectStyles,
    control: (provided) => ({
      ...provided,
      borderColor: 'var(--medium-gray)',
      borderRadius: 'var(--border-radius)',
      boxShadow: 'none',
      cursor: 'pointer',
      minHeight: '44px',
      '&:hover': {
        borderColor: 'var(--primary-color)'
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'var(--primary-color)',
      '&:hover': {
        color: 'var(--primary-dark)'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
    })
  };

  // Render phone input component (used in both login and register forms)
  const renderPhoneInput = () => (
    <div className="form-group">
      <label htmlFor="phone">Phone Number:</label>
      <Select
        id="country"
        options={countryOptions}
        value={selectedCountry}
        onChange={setSelectedCountry}
        styles={enhancedSelectStyles}
        isSearchable={true}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue
        }}
        placeholder="Select or search country"
        noOptionsMessage={() => "No matches"}
      />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <div style={{ flexShrink: 0, padding: '0 10px', backgroundColor: 'var(--light-gray)', height: '44px', display: 'flex', alignItems: 'center', borderRadius: 'var(--border-radius) 0 0 var(--border-radius)', border: '1px solid var(--medium-gray)', borderRight: 'none' }}>
          {selectedCountry?.dialCode}
        </div>
        <input
          type="text"
          id="phone"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          onPaste={(e) => handlePhonePaste(e.target)}
          onInput={handlePhoneInput}
          placeholder="Enter phone number"
          onKeyDown={(e) => handleEnterKeySubmit(e, handleAuth)}
          style={{ borderRadius: '0 var(--border-radius) var(--border-radius) 0', flex: 1 }}
          ref={phoneInputRef}
          autoFocus={authStep === 'login'} // Set autofocus on login screen
        />
      </div>
      <small>Or enter with + prefix to auto-detect country</small>
    </div>
  );

  // Render login form
  const renderLoginForm = () => (
    <div>
      {renderPhoneInput()}
      
      <button className="auth-submit-btn" onClick={handleAuth} disabled={loading}>
        {loading ? 'Processing...' : 'Send OTP'}
      </button>
      
      <p className="auth-switch-text">
        Don't have an account? 
        <button 
          className="auth-switch-btn" 
          onClick={() => setAuthStep('register')}
          disabled={loading}
          style={{ fontSize: '16px', fontWeight: 'bold', padding: '8px 12px', marginLeft: '10px' }}
        >
          Register
        </button>
      </p>
    </div>
  );

  // Render OTP verification form
  const renderOtpForm = () => (
    <div>
      <div className="form-group">
        <label htmlFor="otp">One-Time Password:</label>
        <input
          type="text"
          id="otp"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder="Enter the code sent to your phone"
          onKeyDown={(e) => handleEnterKeySubmit(e, handleAuth)}
          ref={otpInputRef}
          autoFocus={true} // Always auto-focus OTP input
        />
        <small>A 6-digit code was sent to {getFullPhoneNumber()}</small>
      </div>
      
      <button className="auth-submit-btn" onClick={handleAuth} disabled={loading}>
        {loading ? 'Verifying...' : 'Verify OTP'}
      </button>
      
      <p className="auth-switch-text">
        <button 
          className="auth-switch-btn" 
          onClick={() => setAuthStep('login')}
          disabled={loading}
          style={{ fontSize: '16px', fontWeight: 'bold', padding: '8px 12px' }}
        >
          ← Back to login
        </button>
      </p>
    </div>
  );

  // Render registration form
  const renderRegisterForm = () => (
    <div>
      {renderPhoneInput()}
      
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="you@example.com"
          onKeyDown={(e) => handleEnterKeySubmit(e, handleAuth)}
          ref={emailInputRef}
          autoFocus={authStep === 'register'} // Set autofocus on register screen
        />
      </div>
      
      {/* Conditional rendering of referral code section */}
      {showReferralInput ? (
        <div className="form-group">
          <label htmlFor="referral-code">Referral Code:</label>
          <input
            type="text"
            id="referral-code"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
            placeholder="Enter referral code"
            ref={referralInputRef}
            className="referral-input"
          />
          <small className="referral-bonus-text">
            You'll receive 25 free credits when you sign up with this referral code!
          </small>
        </div>
      ) : (
        <div className="referral-link-container">
          <a href="#" className="referral-link" onClick={(e) => {
            e.preventDefault();
            toggleReferralInput();
          }}>
            I have a referral code
          </a>
        </div>
      )}
      
      <button className="auth-submit-btn" onClick={handleAuth} disabled={loading}>
        {loading ? 'Processing...' : 'Register'}
      </button>
      
      <p className="auth-switch-text">
        Already have an account? 
        <button 
          className="auth-switch-btn" 
          onClick={() => setAuthStep('login')}
          disabled={loading}
          style={{ fontSize: '16px', fontWeight: 'bold', padding: '8px 12px', marginLeft: '10px' }}
        >
          Login
        </button>
      </p>
    </div>
  );

  // Determine which form to render based on auth step
  const renderAuthForm = () => {
    if (authStep === 'login') {
      return renderLoginForm();
    } else if (authStep === 'otp') {
      return renderOtpForm();
    } else if (authStep === 'register') {
      return renderRegisterForm();
    }
  };

  return (
    <BaseModal 
      onClose={onClose}
      onModalClose={onModalClose}
      title={authStep === 'register' ? 'Register' : authStep === 'otp' ? 'Enter OTP' : 'Login'}
      maxWidth="500px"
    >
      <div className="auth-form">
        {renderAuthForm()}
      </div>
      
      <style jsx>{`
        .referral-link-container {
          margin: 10px 0 20px;
          text-align: left;
        }
        
        .referral-link {
          color: var(--primary-color);
          text-decoration: none;
          font-size: 14px;
          display: inline-flex;
          align-items: center;
        }
        
        .referral-link:hover {
          text-decoration: underline;
        }
        
        .referral-input {
          border: 1px solid var(--medium-gray);
          border-radius: var(--border-radius);
          padding: 10px 15px;
          font-size: 16px;
          width: 100%;
          transition: border-color 0.2s;
        }
        
        .referral-input:focus {
          border-color: var(--primary-color);
          outline: none;
        }
        
        .referral-bonus-text {
          color: var(--primary-color);
          font-size: 14px;
          margin-top: 5px;
          display: block;
        }
      `}</style>
    </BaseModal>
  );
};

export default AuthModal;