import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { smsService, authService } from '../../services/api';
import { useUser } from '../../contexts/UserContext';
import { normalizePhoneNumber } from '../../utils/helpers';
import QuickTopUpModal from '../payment/QuickTopUpModal';
import draftMessageService from '../../services/draftMessageService';
import PromotionBanner from '../marketing/PromotionBanner';
import SecurityBadges from '../trust/SecurityBadges';

// Import sub-components
import SenderInput from './SenderInput';
import RecipientInput from './RecipientInput';
import MessageInput from './MessageInput';
import MessagePreview from './MessagePreview';
import CSVSelector from './CSVSelector';

const SendSmsForm = () => {
  const { isAuthorized, setShowAuth, updateUserData, userData } = useUser();
  
  // Form state
  const [sender, setSender] = useState('');
  const [receivers, setReceivers] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [draftLastSaved, setDraftLastSaved] = useState(null);
  const [draftSaving, setDraftSaving] = useState(false);
  
  // CSV state
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [csvColumns, setCsvColumns] = useState([]);
  const [phoneColumnIndex, setPhoneColumnIndex] = useState(-1);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [previewContact, setPreviewContact] = useState(null);
  
  // Credit handling states
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [requiredCredits, setRequiredCredits] = useState(0);
  const [pendingSendData, setPendingSendData] = useState(null);
  
  // Show promotions to unauthenticated users
  const [showPromotion, setShowPromotion] = useState(false);

  useEffect(() => {
    if (!isAuthorized) {
      const timer = setTimeout(() => {
        setShowPromotion(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isAuthorized]);
  
  // Create debounce function
  const debounce = (func, wait) => {
    let timeout;
    
    const debounced = function() {
      const context = this;
      const args = arguments;
      
      const later = function() {
        timeout = null;
        func.apply(context, args);
      };
      
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
    
    debounced.cancel = function() {
      clearTimeout(timeout);
    };
    
    return debounced;
  };
  
  // Save draft when form changes - use useCallback to retain the function reference
  const saveDraft = useCallback(
    debounce(() => {
      if (!sender && !receivers && !message) {
        // Don't save empty drafts
        return;
      }
      
      setDraftSaving(true);
      
      const formData = { sender, receivers, message };
      const userId = userData?.id || null;
      
      const success = draftMessageService.saveDraft(formData, userId);
      
      if (success) {
        setDraftLastSaved(new Date());
        setDraftSaving(false);
      }
    }, 2000), // Save 2 seconds after typing stops
    [sender, receivers, message, userData]
  );
  
  // Watch for form changes to auto-save
  useEffect(() => {
    saveDraft();
    
    // Cleanup
    return () => {
      if (typeof saveDraft.cancel === 'function') {
        saveDraft.cancel();
      }
    };
  }, [sender, receivers, message, saveDraft]);
  
  // Load saved drafts
  useEffect(() => {
    if (isAuthorized || !isAuthorized) { // Run for both auth states
      const userId = userData?.id || null;
      const savedDraft = draftMessageService.loadDraft(userId);
      
      if (savedDraft) {
        setSender(savedDraft.sender || '');
        setReceivers(savedDraft.receivers || '');
        setMessage(savedDraft.message || '');
        setDraftLastSaved(new Date(savedDraft.lastSaved));
      }
    }
  }, [userData?.id, isAuthorized]);
  
  // Handle login events by transferring any drafts
  useEffect(() => {
    if (isAuthorized && userData?.id) {
      draftMessageService.transferDrafts(userData.id);
    }
  }, [isAuthorized, userData?.id]);
  
  // Load saved form data when component mounts or auth state changes
  useEffect(() => {
    if (isAuthorized) {
      loadFormData();
    }
  }, [isAuthorized]);
  
  // Save form data to localStorage
  const saveFormData = () => {
    if (!isAuthorized) return;
    
    localStorage.setItem('smsForm', JSON.stringify({ sender, receivers, message }));
  };
  
  // Load saved form data from localStorage
  const loadFormData = () => {
    const savedForm = localStorage.getItem('smsForm');
    if (savedForm) {
      try {
        const formData = JSON.parse(savedForm);
        setSender(formData.sender || '');
        setReceivers(formData.receivers || '');
        setMessage(formData.message || '');
      } catch (error) {
        console.error('Error parsing saved form data:', error);
        localStorage.removeItem('smsForm');
      }
    }
  };
  
  // Handle phone column selection
  const handlePhoneColumnSelect = (index) => {
    setPhoneColumnIndex(index);
    
    // Extract and normalize phone numbers from the selected column
    if (csvData && csvData.length > 0) {
      const columnName = csvColumns[index];
      
      // Check if we already have normalized versions
      const hasNormalizedField = `_normalized_${columnName}` in csvData[0];
      
      const normalizedPhones = csvData
        .map(row => {
          if (hasNormalizedField && row[`_normalized_${columnName}`]) {
            return row[`_normalized_${columnName}`];
          }
          return normalizePhoneNumber(row[columnName]);
        })
        .filter(Boolean); // Remove empty values
      
      // Join the numbers with newlines
      setReceivers(normalizedPhones.join('\n'));
    }
    
    setShowCSVModal(false);
  };
  
  // Check if enough credits are available
  const checkCredits = () => {
    const messageProps = { messageCount: 1 }; // This should come from MessageInput
    const receiverCount = receivers.split(/[\n,]/).filter(r => r.trim()).length;
    const needed = receiverCount * messageProps.messageCount;
    const available = userData?.credits || 0;
    
    if (needed > available) {
      setRequiredCredits(needed - available);
      return false;
    }
    
    return true;
  };
  
  // Handle top-up modal close
  const handleTopUpModalClose = (e) => {
    if (e?.target?.classList?.contains('modal')) {
      setShowTopUpModal(false);
      setPendingSendData(null);
    }
  };
  
  // Handle top-up completion
  const handleTopUpClose = (success) => {
    setShowTopUpModal(false);
    
    if (success && pendingSendData) {
      // Try sending again with the saved data
      processSendSMS(pendingSendData.normalizedReceivers, pendingSendData.selectedColumn);
    } else {
      setPendingSendData(null);
      setLoading(false);
    }
  };
  
  // Process the SMS sending with auto top-up support
  const processSendSMS = async (normalizedReceivers, selectedColumn) => {
    try {
      // Call the unified SMS endpoint with or without CSV data
      const result = await smsService.sendSMS(
        sender, 
        normalizedReceivers, 
        message, 
        csvData, 
        selectedColumn
      );
      
      if (result.success) {
        // Show success message
        toast.success(`Message successfully sent to ${result.recipients_success} recipients!`);
        
        // Save form data
        saveFormData();
        
        // Clear draft after successful send
        const userId = userData?.id || null;
        draftMessageService.clearDraft(userId);
        setDraftLastSaved(null);
        
        // Clear pending data
        setPendingSendData(null);
        
        // If auto top-up was triggered, show a notification
        if (result.auto_topup_triggered) {
          toast.info(`Your account was automatically topped up with ${result.auto_topup_credits} credits.`);
        }
        
        // Refresh user data to update credits
        try {
          const userData = await authService.refreshUserData();
          if (userData) {
            updateUserData(userData);
          }
        } catch (refreshError) {
          console.error('Error refreshing user data:', refreshError);
        }
      }
    } catch (error) {
      console.error('Send SMS error:', error);
      
      // Special handling for auto top-up failures
      if (error.response && error.response.status === 402 && error.response.data) {
        const errorData = error.response.data;
        
        if (errorData.auto_topup_attempted) {
          if (errorData.auto_topup_successful) {
            toast.success(`Your account was automatically topped up with ${errorData.credits_added} credits.`);
            
            // Refresh user data to update credits
            try {
              const updatedUserData = await authService.refreshUserData();
              if (updatedUserData) {
                updateUserData(updatedUserData);
              }
            } catch (refreshError) {
              console.error('Error refreshing user data:', refreshError);
            }
            
            // If still insufficient, show the quick top-up modal
            if (errorData.error === 'Still insufficient credits after auto top-up') {
              setRequiredCredits(errorData.credits_required - errorData.credits_available);
              setShowTopUpModal(true);
            }
          } else {
            toast.error(`Auto top-up failed: ${errorData.auto_topup_error || 'Unknown error'}`);
            setRequiredCredits(errorData.credits_required - errorData.credits_available);
            setShowTopUpModal(true);
          }
        } else if (errorData.credits_required) {
          // Regular insufficient credits error
          setRequiredCredits(errorData.credits_required - (errorData.credits_available || 0));
          setShowTopUpModal(true);
        } else {
          toast.error(`Error sending message: ${errorData.error || 'Unknown error'}`);
          setPendingSendData(null);
        }
      } else {
        toast.error(`Error sending message: ${error.message || 'Unknown error'}`);
        setPendingSendData(null);
      }
    } finally {
      if (!showTopUpModal) {
        setLoading(false);
      }
    }
  };
  
  // Handle sending the message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!isAuthorized) {
      setShowAuth(true);
      return;
    }
    
    if (!sender.trim()) {
      toast.error('Please enter a sender name');
      return;
    }
    
    if (!receivers.trim()) {
      toast.error('Please enter at least one receiver');
      return;
    }
    
    if (!message.trim()) {
      toast.error('Please enter a message');
      return;
    }
    
    setLoading(true);
    
    // Check if enough credits are available
    if (!checkCredits()) {
      const normalizedReceivers = receivers
        .split(/[\n,]/)
        .map(r => r.trim())
        .filter(Boolean)
        .join(',');
      
      // Save sending data for later if top-up succeeds
      setPendingSendData({
        normalizedReceivers,
        selectedColumn: csvColumns[phoneColumnIndex]
      });
      
      setShowTopUpModal(true);
      return;
    }
    
    // Normalize phone numbers before sending
    const normalizedReceivers = receivers
      .split(/[\n,]/)
      .map(r => r.trim())
      .filter(Boolean)
      .join(',');
    
    // Get the selected column name if CSV is being used
    const selectedColumn = csvColumns[phoneColumnIndex];
    
    await processSendSMS(normalizedReceivers, selectedColumn);
  };
  
  // Format last saved time
  const formatLastSaved = () => {
    if (!draftLastSaved) return '';
    
    const now = new Date();
    const diff = Math.floor((now - draftLastSaved) / 1000); // seconds
    
    if (diff < 60) return 'just now';
    if (diff < 3600) return `${Math.floor(diff / 60)} minutes ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)} hours ago`;
    return `${Math.floor(diff / 86400)} days ago`;
  };
  
  return (
    <main className="form-preview-container">
      {!isAuthorized && showPromotion && (
        <PromotionBanner 
          onClose={() => setShowPromotion(false)}
          onClick={() => setShowAuth(true)}
        />
      )}
      
      <div className="form-container">
        {draftLastSaved && (
          <div className="draft-status">
            <span>
              {draftSaving ? 'Saving draft...' : `Draft saved ${formatLastSaved()}`}
            </span>
          </div>
        )}
        
        <form onSubmit={handleSendMessage}>
          <SenderInput 
            sender={sender}
            setSender={setSender}
          />

          <RecipientInput 
            receivers={receivers}
            setReceivers={setReceivers}
            csvData={csvData}
            setCsvData={setCsvData}
            setCsvColumns={setCsvColumns}
            setShowCSVModal={setShowCSVModal}
            file={file}
            setFile={setFile}
            setPhoneColumnIndex={setPhoneColumnIndex}
            setPreviewContact={setPreviewContact}
          />

          <MessageInput 
            message={message}
            setMessage={setMessage}
            csvColumns={csvColumns}
            userData={userData}
            isAuthorized={isAuthorized}
            receivers={receivers}
          />

          <button type="submit" className="send-btn" disabled={loading}>
            {loading ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </div>
      
      {/* CSV Column Selection Modal */}
      {showCSVModal && (
        <CSVSelector
          csvColumns={csvColumns}
          csvData={csvData}
          onSelect={handlePhoneColumnSelect}
        />
      )}

      <MessagePreview 
        sender={sender}
        message={message}
        csvData={csvData}
        csvColumns={csvColumns}
        phoneColumnIndex={phoneColumnIndex}
        previewContact={previewContact}
        setPreviewContact={setPreviewContact}
      />
      
      {/* Security Badges and Compliance Information */}
      <SecurityBadges />
      
      {/* Top-up Modal */}
      {showTopUpModal && (
        <QuickTopUpModal
          onClose={handleTopUpClose}
          onModalClose={handleTopUpModalClose}
          requiredCredits={requiredCredits}
        />
      )}
    </main>
  );
};

export default SendSmsForm;