/**
 * Utility functions for calculating message properties and credits
 */

/**
 * Determine if a message contains special characters outside of GSM-7 charset
 * @param {string} message - The message text
 * @returns {boolean} - True if message contains special characters
 */
export const hasSpecialCharacters = (message) => {
    // GSM 7-bit alphabet and extension characters
    const gsm7 = "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1BÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\\[~]|€";
    const gsm7ext = "````````````````````^```````````````````{}`````\\````````````[~]`|``````````````````````````````````````€``````````````````````````";
    
    // Check each character
    for (let i = 0; i < message.length; i++) {
      const char = message.charAt(i);
      if (gsm7.indexOf(char) === -1 && gsm7ext.indexOf(char) === -1) {
        return true;
      }
    }
    
    return false;
  };
  
  /**
   * Calculate message properties based on its content
   * @param {string} message - The message text
   * @returns {Object} - Object with message properties
   */
  export const calculateMessageProperties = (message) => {
    if (!message) {
      return {
        length: 0,
        hasSpecialChars: false,
        singleMessageLimit: 160,
        multiMessageLimit: 153,
        messageCount: 0
      };
    }
    
    // Check for special characters
    const hasSpecialChars = hasSpecialCharacters(message);
    
    // Set character limits based on encoding
    const singleMessageLimit = hasSpecialChars ? 70 : 160;
    const multiMessageLimit = hasSpecialChars ? 67 : 153;
    
    // Calculate message count
    let messageCount = 1;
    const length = message.length;
    
    if (length <= singleMessageLimit) {
      messageCount = 1;
    } else {
      messageCount = Math.ceil(length / multiMessageLimit);
    }
    
    return {
      length,
      hasSpecialChars,
      singleMessageLimit,
      multiMessageLimit,
      messageCount
    };
  };
  
  /**
   * Calculate required credits based on message properties and receivers
   * @param {string} message - The message text
   * @param {string} receivers - Comma or newline separated list of receivers
   * @returns {number} - Required credits
   */
  export const calculateRequiredCredits = (message, receivers) => {
    if (!message || !receivers.trim()) {
      return 0;
    }
    
    // Calculate message segments
    const messageProps = calculateMessageProperties(message);
    
    // Count recipients
    const recipientCount = receivers.split(/[\n,]/).filter(r => r.trim()).length;
    
    // Calculate total credits required
    return messageProps.messageCount * recipientCount;
  };
  
  export default {
    calculateMessageProperties,
    calculateRequiredCredits,
    hasSpecialCharacters
  };