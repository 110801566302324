import React, { useState } from 'react';
import { FaTwitter, FaFacebook, FaLinkedin, FaWhatsapp, FaEnvelope, FaQrcode, FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import referralService from '../../services/referralService';
import { QRCodeSVG } from 'qrcode.react'; // Fixed import statement

const ReferralShare = ({ referralCode, sharingText, referralUrl }) => {
  const [showQRCode, setShowQRCode] = useState(false);
  const [copied, setCopied] = useState(false);
  
  const handleShareTwitter = () => {
    referralService.shareToTwitter(sharingText.twitter);
  };
  
  const handleShareFacebook = () => {
    referralService.shareToFacebook(referralUrl);
  };
  
  const handleShareLinkedIn = () => {
    referralService.shareToLinkedIn(referralUrl, 'Try ToneSMS with my referral code');
  };
  
  const handleShareWhatsApp = () => {
    referralService.shareToWhatsApp(sharingText.whatsapp);
  };
  
  const handleShareEmail = () => {
    referralService.shareToEmail(
      'Join ToneSMS and get free SMS credits',
      sharingText.email
    );
  };
  
  const toggleQRCode = () => {
    setShowQRCode(!showQRCode);
  };
  
  const handleCopyText = async () => {
    try {
      const textToCopy = `Sign up for ToneSMS using my referral code ${referralCode} and get 25 free credits!\nRegister here: ${referralUrl}`;
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      toast.success('Custom text copied to clipboard!');
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error('Could not copy to clipboard. Please try again.');
    }
  };
  
  return (
    <div className="referral-share">
      <h3>Share your referral link</h3>
      <p>Share your referral link via social media, email, or generate a QR code.</p>
      
      <div className="share-buttons">
        <button className="share-button twitter" onClick={handleShareTwitter}>
          <FaTwitter />
          <span>Twitter</span>
        </button>
        
        <button className="share-button facebook" onClick={handleShareFacebook}>
          <FaFacebook />
          <span>Facebook</span>
        </button>
        
        <button className="share-button linkedin" onClick={handleShareLinkedIn}>
          <FaLinkedin />
          <span>LinkedIn</span>
        </button>
        
        <button className="share-button whatsapp" onClick={handleShareWhatsApp}>
          <FaWhatsapp />
          <span>WhatsApp</span>
        </button>
        
        <button className="share-button email" onClick={handleShareEmail}>
          <FaEnvelope />
          <span>Email</span>
        </button>
        
        <button className="share-button qrcode" onClick={toggleQRCode}>
          <FaQrcode />
          <span>QR Code</span>
        </button>
      </div>
      
      {showQRCode && (
        <div className="qr-code-container">
          <QRCodeSVG value={referralUrl} size={200} />
          <p>Scan this code to sign up with your referral link</p>
        </div>
      )}
      
      <div className="share-custom">
        <h4>Or just share this text:</h4>
        <div className="share-text">
          <p>Sign up for ToneSMS using my referral code <strong>{referralCode}</strong> and get 25 free credits!</p>
          <p>Register here: {referralUrl}</p>
          <button 
            className="copy-custom-button"
            onClick={handleCopyText}
          >
            <FaCopy /> {copied ? 'Copied!' : 'Copy Text'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralShare;