import React from 'react';

/**
 * Component for sender name input with character limit
 */
const SenderInput = ({ sender, setSender }) => {
  return (
    <div className="form-group">
      <label htmlFor="sender">Sender Name:</label>
      <input
        type="text"
        id="sender"
        value={sender}
        onChange={(e) => setSender(e.target.value)}
        placeholder="Your Company Name"
        maxLength="11"
      />
      <small>Maximum 11 characters</small>
    </div>
  );
};

export default SenderInput;