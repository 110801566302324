import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { contactService } from '../services/api';
import { handleEnterKeySubmit } from '../utils/helpers';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!name.trim()) {
      toast.error('Please enter your name');
      return;
    }
    
    if (!email.trim()) {
      toast.error('Please enter your email');
      return;
    }
    
    if (!message.trim()) {
      toast.error('Please enter a message');
      return;
    }
    
    setLoading(true);
    
    try {
      // Send contact form to server
      const result = await contactService.sendContactMessage(name, email, message);
      
      if (result.success) {
        // Show success message
        setSubmitted(true);
        toast.success('Your message has been sent successfully!');
        
        // Reset form
        setName('');
        setEmail('');
        setMessage('');
      }
    } catch (error) {
      toast.error(`Error sending message: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      
      {submitted ? (
        <div className="success-message">
          <h2>Thank you for your message!</h2>
          <p>We have received your inquiry and will respond as soon as possible.</p>
          <button onClick={() => setSubmitted(false)}>Send Another Message</button>
        </div>
      ) : (
        <div className="contact-content">
          <div className="contact-info">
            <h2>Get in Touch</h2>
            <p>We'd love to hear from you. Please fill out the form or use the contact information below.</p>
            
            <div className="contact-details">
              <div className="contact-item">
                <strong>Email:</strong> support@tonesms.com
              </div>
              <div className="contact-item">
                <strong>Phone:</strong> +371 2256 5353
              </div>
            </div>
          </div>
          
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => handleEnterKeySubmit(e, handleSubmit)}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => handleEnterKeySubmit(e, handleSubmit)}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="6"
                required
              ></textarea>
            </div>
            
            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Contact;