// referralService.js - Frontend service for referral functionality
import { normalizePhoneNumber } from '../utils/helpers';

// Get the base API URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.tonesms.com';

// Helper function for API requests (copied from api.js since it's not exported)
const apiRequest = async (endpoint, method = 'GET', data = null, useToken = true) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  // Add authorization header if token exists and useToken is true
  if (useToken && localStorage.getItem('token')) {
    headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  }

  const config = {
    method,
    headers,
  };

  if (data) {
    config.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, config);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error || 'API request failed');
    }

    return result;
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
};

// Referral service for frontend
const referralService = {
  // Generate or retrieve referral code
  generateReferralCode: async () => {
    return apiRequest('/referral/code/generate', 'POST');
  },
  
  // Get referral statistics
  getReferralStats: async () => {
    return apiRequest('/referral/stats', 'GET');
  },
  
  // Send referral invitations via email
  sendReferralInvites: async (emails) => {
    return apiRequest('/referral/invite', 'POST', { emails });
  },
  
  // Apply a referral code (during registration)
  applyReferralCode: async (referralCode, userId) => {
    return apiRequest('/referral/apply', 'POST', { 
      referral_code: referralCode,
      referee_id: userId
    }, false); // No auth token needed for registration
  },
  
  // Complete a referral when user makes first payment
  completeReferral: async () => {
    return apiRequest('/referral/complete', 'POST');
  },
  
  // Format sharing text for social media
  formatSharingText: (referralCode, baseUrl = window.location.origin) => {
    const referralUrl = `${baseUrl}/register?ref=${referralCode}`;
    
    return {
      twitter: `Try ToneSMS for your SMS messaging needs! Sign up using my referral link and get 25 free credits to start: ${referralUrl}`,
      facebook: `I've been using ToneSMS for my SMS messaging needs. Sign up with my referral link and you'll get 25 free credits to start sending SMS messages right away! ${referralUrl}`,
      email: `I wanted to share ToneSMS with you - it's a great platform for sending SMS messages. Use my referral link to sign up and you'll get 25 free credits: ${referralUrl}`,
      whatsapp: `Hey! Check out ToneSMS for SMS messaging. Use my referral link to get 25 free credits: ${referralUrl}`,
      linkedin: `I recommend ToneSMS for business SMS messaging. Use my referral link to get started with 25 free credits: ${referralUrl}`,
      url: referralUrl
    };
  },
  
  // Share to social media platforms
  shareToTwitter: (text) => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
    window.open(url, '_blank', 'width=550,height=420');
  },
  
  shareToFacebook: (url) => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(shareUrl, '_blank', 'width=550,height=420');
  },
  
  shareToLinkedIn: (url, title) => {
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`;
    window.open(shareUrl, '_blank', 'width=550,height=420');
  },
  
  shareToWhatsApp: (text) => {
    const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`;
    window.open(shareUrl, '_blank', 'width=550,height=420');
  },
  
  shareToEmail: (subject, body) => {
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  }
};

export default referralService;