import React, { useState, useEffect, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { authService } from '../../services/api';
import { UserContext } from '../../contexts/UserContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckout from '../payment/StripeCheckout';
import { useEnterKeySubmit } from '../../utils/enterKeyHandler';

// Load stripe outside of component to avoid recreating it on renders
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const OnboardingModal = ({ onClose, onModalClose }) => {
  const { userData, updateUserData, setShowSettings } = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [error, setError] = useState('');
  const [hasSkipped, setHasSkipped] = useState(false);

  // Check localStorage for previous skipped status
  useEffect(() => {
    const skippedStatus = localStorage.getItem('onboarding_skipped');
    if (skippedStatus === 'true') {
      setHasSkipped(true);
    }
  }, []);

  // Handle the browser's back button or page refresh - use useCallback
  const handleBeforeUnload = useCallback((event) => {
    if (!userData.emailVerified && userData.credits < 5) {
      // The message won't be displayed on modern browsers for security reasons,
      // but the event will still be triggered
      const message = "Your email verification is not complete. Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    }
  }, [userData]);

  useEffect(() => {
    // Check if email is already verified (user has existing credits)
    if (userData.emailVerified || userData.credits >= 5) {
      setEmailVerified(true);
      setCurrentStep(2);
    }
    
    // Set onboarding state in localStorage to persist across refreshes
    if (!userData.emailVerified && userData.credits < 5 && userData.id) {
      localStorage.setItem('onboarding_pending', 'true');
    }
  }, [userData]);

  // Add event listener for page unload/refresh
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  const handleSendVerification = async () => {
    setLoading(true);
    setError('');
    
    try {
      const result = await authService.requestEmailChange(userData.email);
      
      if (result.success) {
        setVerificationSent(true);
        toast.success('Verification code sent to your email!');
      } else {
        setError(result.error || 'Failed to send verification code');
        toast.error('Failed to send verification code');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyEmail = async () => {
    if (!verificationCode) {
      setError('Please enter the verification code');
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      const result = await authService.verifyEmailChange(verificationCode);
      
      if (result.success) {
        // Add 5 credits for email verification
        const updatedUserData = await authService.refreshUserData();
        
        if (updatedUserData) {
          updateUserData(updatedUserData);
          setEmailVerified(true);
          setCurrentStep(2);
          toast.success('Email verified! You received 5 credits.');
          
          // Clear the onboarding pending flag since email is now verified
          localStorage.removeItem('onboarding_pending');
          // Clear any skipped status
          localStorage.removeItem('onboarding_skipped');
          setHasSkipped(false);
        }
      } else {
        setError(result.error || 'Invalid verification code');
        toast.error('Invalid verification code');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Enable Enter key for verification code
  useEnterKeySubmit(handleVerifyEmail, [verificationCode, loading]);

  const handlePaymentSuccess = async (result) => {
    console.log("Payment success with result:", result);
    
    try {
      // Explicitly refresh user data to get updated credits
      const updatedUserData = await authService.refreshUserData();
      if (updatedUserData) {
        updateUserData(updatedUserData);
        console.log("User data refreshed with credits:", updatedUserData.credits);
      }
      
      // Show toast with the actual added credits
      toast.success(`Welcome package activated! Added ${result.credits_added || 50} credits to your account.`);
      
      // Clear the onboarding pending flag once payment is complete
      localStorage.removeItem('onboarding_pending');
      // Clear any skipped status
      localStorage.removeItem('onboarding_skipped');
      setHasSkipped(false);
      
      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error updating user data after payment:", error);
      toast.error("Payment successful, but failed to update credit balance. Please refresh the page.");
      
      // Still close the modal and clear pending flag
      localStorage.removeItem('onboarding_pending');
      onClose();
    }
  };

  const handleSkip = () => {
    // If user skips, mark this in localStorage so we know they've seen the offer
    localStorage.setItem('onboarding_skipped', 'true');
    localStorage.removeItem('onboarding_pending');
    setHasSkipped(true);
    onClose();
  };

  const handleGoToSettings = () => {
    onClose();
    // Show the settings modal
    setShowSettings(true);
  };

  return (
    <div className="modal onboarding-modal" onClick={onModalClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Welcome to ToneSMS!</h2>
          {/* Only show close button in step 2 or if user has credits */}
          {(currentStep === 2 || userData.credits > 0) && (
            <button className="close-btn" onClick={onClose}>&times;</button>
          )}
        </div>
        
        <div className="modal-body">
          <div className="onboarding-progress">
            <div className={`progress-step ${currentStep >= 1 ? 'active' : ''} ${emailVerified ? 'completed' : ''}`}>
              <div className="step-number">1</div>
              <div className="step-label">Verify Email</div>
            </div>
            <div className="progress-connector"></div>
            <div className={`progress-step ${currentStep >= 2 ? 'active' : ''}`}>
              <div className="step-number">2</div>
              <div className="step-label">Get Credits</div>
            </div>
          </div>

          {currentStep === 1 && (
            <div className="onboarding-step">
              <h3>Verify Your Email</h3>
              <p>Verify your email address to receive your first 5 credits!</p>
              
              {!verificationSent ? (
                <div className="email-verification">
                  <p>We'll send a verification code to: <strong>{userData.email}</strong></p>
                  <button 
                    className="primary-btn" 
                    onClick={handleSendVerification} 
                    disabled={loading}
                  >
                    {loading ? 'Sending...' : 'Send Verification Code'}
                  </button>
                </div>
              ) : (
                <div className="verification-code-form">
                  <p>Enter the 6-digit code sent to your email:</p>
                  <input 
                    type="text" 
                    placeholder="Verification code" 
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    maxLength={6}
                    autoFocus
                  />
                  {error && <p className="error-text">{error}</p>}
                  <div className="verification-actions">
                    <button 
                      className="secondary-btn" 
                      onClick={() => setVerificationSent(false)} 
                      disabled={loading}
                    >
                      Resend Code
                    </button>
                    <button 
                      className="primary-btn" 
                      onClick={handleVerifyEmail} 
                      disabled={loading}
                    >
                      {loading ? 'Verifying...' : 'Verify Email'}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          {currentStep === 2 && (
            <div className="onboarding-step">
              <h3>Get 50 More Credits</h3>
              <p>Complete your setup with an initial payment of just $2 to receive 50 additional credits!</p>
              
              <div className="special-offer">
                <span className="offer-badge">Special Offer</span>
                <h4>New User Welcome Package</h4>
                <p className="offer-price">$2.00</p>
                <p className="offer-credits">50 Credits</p>
                <p className="offer-savings">80% savings compared to regular pricing</p>
              </div>
              
              <Elements stripe={stripePromise}>
                <StripeCheckout 
                  amount={2} 
                  userId={userData.id}
                  setLoading={setLoading}
                  returnUrl={window.location.href}
                  disabled={loading}
                  onPaymentSuccess={handlePaymentSuccess}
                  forceCredits={50} // Force display of 50 credits instead of calculated amount
                  saveCardForced={true} // Always save the card for onboarding
                />
              </Elements>
              
              <div className="skip-option">
                <button 
                  className="text-btn" 
                  onClick={handleSkip}
                >
                  Skip for now (can be purchased later)
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;