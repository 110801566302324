/**
 * Utility functions for working with message placeholders
 */

// Default placeholders that are always available
export const defaultPlaceholders = [
    { 
      name: 'current_date', 
      description: 'Current date (e.g., March 8, 2025)', 
      getValue: () => new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) 
    },
    { 
      name: 'short_date', 
      description: 'Short date format (e.g., 03/08/2025)', 
      getValue: () => new Date().toLocaleDateString('en-US') 
    },
    { 
      name: 'time', 
      description: 'Current time (e.g., 3:30 PM)', 
      getValue: () => new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }) 
    },
    { 
      name: 'day', 
      description: 'Current day (e.g., Saturday)', 
      getValue: () => new Date().toLocaleDateString('en-US', { weekday: 'long' }) 
    },
    { 
      name: 'month', 
      description: 'Current month (e.g., March)', 
      getValue: () => new Date().toLocaleDateString('en-US', { month: 'long' }) 
    },
    { 
      name: 'year', 
      description: 'Current year (e.g., 2025)', 
      getValue: () => new Date().getFullYear().toString() 
    }
  ];
  
  /**
   * Helper function to escape special regex characters
   * @param {string} string - The string to escape
   * @returns {string} Escaped string
   */
  export const escapeRegex = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };
  
  /**
   * Replace placeholders in text with values from contact data
   * @param {string} text - Text with placeholders
   * @param {Object} contactData - Contact data for personalization 
   * @returns {string} Text with placeholders replaced
   */
  export const replacePlaceholders = (text, contactData = {}) => {
    if (!text) return text;
    
    let result = text;
    
    // Use a regex WITHOUT the global flag first to check if there are any placeholders
    if (!/{{[^{}]+}}/.test(result)) {
      return result; // No placeholders, return as is
    }
    
    // Find all {{placeholders}} - using matchAll is more reliable than exec with g flag
    const placeholderRegex = /{{([^{}]+)}}/g;
    const matches = [...result.matchAll(placeholderRegex)];
    
    // Process each match
    for (const match of matches) {
      const placeholder = match[0]; // {{column_name}}
      const columnName = match[1].trim(); // column_name (with whitespace trimmed)
      
      // Check if it's from contact data (case-insensitive matching)
      let replacementFound = false;
      
      // First try exact match
      if (contactData && contactData[columnName] !== undefined) {
        result = result.replace(new RegExp(escapeRegex(placeholder), 'g'), contactData[columnName]);
        replacementFound = true;
      } 
      // Then try case-insensitive match if needed
      else if (contactData) {
        const caseInsensitiveKey = Object.keys(contactData).find(
          key => key.toLowerCase() === columnName.toLowerCase()
        );
        
        if (caseInsensitiveKey) {
          result = result.replace(new RegExp(escapeRegex(placeholder), 'g'), contactData[caseInsensitiveKey]);
          replacementFound = true;
        }
      }
      
      // If not found in contact data, check default placeholders
      if (!replacementFound) {
        const defaultPlaceholder = defaultPlaceholders.find(p => p.name === columnName);
        if (defaultPlaceholder) {
          const replacement = defaultPlaceholder.getValue();
          result = result.replace(new RegExp(escapeRegex(placeholder), 'g'), replacement);
        }
      }
    }
    
    return result;
  };