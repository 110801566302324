import React, { useState, useEffect } from 'react';
import { 
  FaShieldAlt, 
  FaLock, 
  FaCheckCircle, 
  FaInfoCircle,
  FaChevronDown,
  FaChevronUp,
  FaServer,
  FaCreditCard
} from 'react-icons/fa';

const SecurityBadges = () => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    window.onLinkClick = (e, type) => {
      e.preventDefault();
      if (typeof window.handleFooterLinkClick === 'function') {
        window.handleFooterLinkClick(e, type);
      }
    };
  }, []);
  
  return (
    <div className="security-badges-container">
      <div 
        className="security-badges-summary"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="security-badges-preview">
          <div className="badge">
            <FaShieldAlt className="badge-icon" />
            <span>GDPR Compliant</span>
          </div>
          <div className="badge">
            <FaLock className="badge-icon" />
            <span>256-bit SSL</span>
          </div>
        </div>
        <button className="toggle-button">
          {expanded ? (
            <>
              <span>Show less</span>
              <FaChevronUp />
            </>
          ) : (
            <>
              <span>Show security details</span>
              <FaChevronDown />
            </>
          )}
        </button>
      </div>
      
      {expanded && (
        <div className="security-badges-details">
          <div className="security-section">
            <h3>Data Protection</h3>
            <div className="badges-row">
              <div className="badge-detailed">
                <FaShieldAlt className="badge-icon" />
                <div className="badge-content">
                  <h4>GDPR Compliant</h4>
                  <p>All user data is processed in accordance with EU General Data Protection Regulation.</p>
                </div>
              </div>
              <div className="badge-detailed">
                <FaCheckCircle className="badge-icon" />
                <div className="badge-content">
                  <h4>Certified Compliance</h4>
                  <p>We adhere to local SMS regulations and telecom requirements in all supported countries.</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="security-section">
            <h3>Security Measures</h3>
            <div className="badges-row">
              <div className="badge-detailed">
                <FaLock className="badge-icon" />
                <div className="badge-content">
                  <h4>256-bit SSL Encryption</h4>
                  <p>All data is encrypted using industry-standard SSL technology to ensure secure transmission.</p>
                </div>
              </div>
              <div className="badge-detailed">
                <FaServer className="badge-icon" />
                <div className="badge-content">
                  <h4>99.9% Uptime SLA</h4>
                  <p>Our infrastructure is built on redundant servers with automatic failover to ensure reliability.</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="security-section">
            <h3>Payment Security</h3>
            <div className="badges-row">
              <div className="badge-detailed">
                <FaCreditCard className="badge-icon" />
                <div className="badge-content">
                  <h4>PCI DSS Compliant</h4>
                  <p>All payments are processed securely in compliance with Payment Card Industry Data Security Standards.</p>
                </div>
              </div>
              <div className="badge-detailed">
                <FaInfoCircle className="badge-icon" />
                <div className="badge-content">
                  <h4>Transparent Pricing</h4>
                  <p>No hidden fees or charges. Pay only for what you use with clear pricing and detailed usage reports.</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="security-footer">
            <p>For more information about our security practices, please review our <a href="#privacy" onClick={(e) => window.onLinkClick(e, 'privacy')}>Privacy Policy</a> and <a href="#terms" onClick={(e) => window.onLinkClick(e, 'terms')}>Terms of Service</a>.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SecurityBadges;