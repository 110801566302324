import React from 'react';
import { defaultPlaceholders } from '../utils/placeholderUtils';

/**
 * Component for displaying and using default placeholders like date/time
 */
const DefaultPlaceholders = ({ onInsertPlaceholder }) => {
  return (
    <div className="placeholders-list default-placeholders">
      {defaultPlaceholders.map((placeholder, index) => (
        <div key={index} className="placeholder-item">
          <button
            type="button"
            className="placeholder-btn"
            onClick={() => onInsertPlaceholder(`{{${placeholder.name}}}`)}
          >
            {`{{${placeholder.name}}}`}
          </button>
          <span className="placeholder-desc">{placeholder.description}</span>
        </div>
      ))}
    </div>
  );
};

export default DefaultPlaceholders;