import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BaseModal from './BaseModal';
import { apiKeyService } from '../../services/api';
import { useUser } from '../../contexts/UserContext';

const ApiDocsModal = ({ onClose, onModalClose }) => {
  const { isAuthorized, userData, updateUserData } = useUser();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('curl'); // Default tab
  
  // API endpoint
  const apiEndpoint = 'https://api.tonesms.com/send';
  
  // API Token to use in examples
  const apiToken = userData?.apiKey || 'your_api_token';

  // Generate new API key
  const handleRegenerateApiKey = async () => {
    if (window.confirm('Are you sure you want to regenerate your API key? Your existing key will no longer work.')) {
      setLoading(true);
      
      try {
        const result = await apiKeyService.regenerateApiKey();
        
        if (result.success) {
          // Update user data with new API key
          const updatedUserData = {...userData, apiKey: result.api_key};
          updateUserData(updatedUserData);
          
          toast.success('Your API key has been regenerated. Make sure to update it in your applications.');
        }
      } catch (error) {
        toast.error(`Error regenerating API key: ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };
  
  // Code examples
  const examples = {
    curl: `curl -X POST ${apiEndpoint} \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer ${apiToken}" \\
  -d '{
    "sender": "YourCompany",
    "receivers": "123456789,987654321",
    "message": "Your message here"
  }'`,
    
    php: `<?php
$apiEndpoint = '${apiEndpoint}';
$apiToken = '${apiToken}';

$data = array(
    'sender' => 'YourCompany',
    'receivers' => '123456789,987654321',
    'message' => 'Your message here'
);

$options = array(
    'http' => array(
        'header'  => "Content-type: application/json\\r\\n" .
                     "Authorization: Bearer " . $apiToken . "\\r\\n",
        'method'  => 'POST',
        'content' => json_encode($data)
    )
);

$context  = stream_context_create($options);
$result = file_get_contents($apiEndpoint, false, $context);
$response = json_decode($result);

print_r($response);
?>`,
    
    python: `import requests
import json

url = "${apiEndpoint}"
headers = {
    "Content-Type": "application/json",
    "Authorization": f"Bearer ${apiToken}"
}
payload = {
    "sender": "YourCompany",
    "receivers": "123456789,987654321",
    "message": "Your message here"
}

response = requests.post(url, headers=headers, json=payload)
result = response.json()

print(result)`,
    
    java: `import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.io.OutputStream;
import java.net.HttpURLConnection;
import java.net.URL;

public class ToneSmsExample {
    public static void main(String[] args) {
        try {
            URL url = new URL("${apiEndpoint}");
            HttpURLConnection conn = (HttpURLConnection) url.openConnection();
            conn.setRequestMethod("POST");
            conn.setRequestProperty("Content-Type", "application/json");
            conn.setRequestProperty("Authorization", "Bearer ${apiToken}");
            conn.setDoOutput(true);

            String jsonInput = "{" +
                "\\"sender\\": \\"YourCompany\\"," +
                "\\"receivers\\": \\"123456789,987654321\\"," +
                "\\"message\\": \\"Your message here\\"" +
                "}";

            OutputStream os = conn.getOutputStream();
            os.write(jsonInput.getBytes());
            os.flush();

            BufferedReader br = new BufferedReader(
                new InputStreamReader(conn.getInputStream())
            );
            
            String output;
            StringBuilder response = new StringBuilder();
            while ((output = br.readLine()) != null) {
                response.append(output);
            }
            
            conn.disconnect();
            
            System.out.println(response.toString());
        } catch (Exception e) {
            e.printStackTrace();
        }
    }
}`,
    
    csharp: `using System;
using System.Net.Http;
using System.Text;
using System.Threading.Tasks;
using Newtonsoft.Json;

namespace ToneSmsExample
{
    class Program
    {
        static async Task Main(string[] args)
        {
            using (HttpClient client = new HttpClient())
            {
                // Set the Authorization header
                client.DefaultRequestHeaders.Add("Authorization", "Bearer ${apiToken}");

                var payload = new
                {
                    sender = "YourCompany",
                    receivers = "123456789,987654321",
                    message = "Your message here"
                };

                var content = new StringContent(
                    JsonConvert.SerializeObject(payload),
                    Encoding.UTF8,
                    "application/json"
                );

                HttpResponseMessage response = await client.PostAsync(
                    "${apiEndpoint}",
                    content
                );

                string result = await response.Content.ReadAsStringAsync();
                Console.WriteLine(result);
            }
        }
    }
}`
  };
  
  // Copy example to clipboard
  const copyExample = () => {
    navigator.clipboard.writeText(examples[activeTab]);
    toast.info(`${activeTab.toUpperCase()} example copied to clipboard!`);
  };

  return (
    <BaseModal
      onClose={onClose}
      onModalClose={onModalClose}
      title="API Documentation"
      maxWidth="900px"
    >
      <div className="api-docs">
        <div className="api-key-section">
          <h3>Your API Key</h3>
          {isAuthorized ? (
            <div className="api-key-container">
              <input 
                type="text" 
                value={userData.apiKey || ''} 
                readOnly 
                className="api-key-input"
              />
              <button 
                className="copy-btn" 
                onClick={() => {
                  navigator.clipboard.writeText(userData.apiKey || '');
                  toast.info('API key copied to clipboard');
                }}
                disabled={loading || !userData.apiKey}
              >
                Copy
              </button>
              <button 
                className="rotate-btn"
                onClick={handleRegenerateApiKey}
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Rotate Key'}
              </button>
            </div>
          ) : (
            <div className="not-authorized-message">
              <p>You need to be logged in to view and manage your API key.</p>
              <p>Once you're authorized, your API key will be displayed here.</p>
            </div>
          )}
          <p className="api-key-warning">
            Keep your API key secret. Do not share it in public repositories or client-side code.
          </p>
        </div>
        
        <section>
          <h3>Endpoint</h3>
          <p>
            <code>POST https://api.tonesms.com/send</code>
          </p>
          
          <h3>Parameters</h3>
          <table>
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Type</th>
                <th>Description</th>
                <th>Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Authorization</td>
                <td>Header</td>
                <td>Bearer token authentication (e.g., "Bearer your_api_token")</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>sender</td>
                <td>string</td>
                <td>Sender name (max 11 characters)</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>receivers</td>
                <td>string</td>
                <td>Comma-separated list of phone numbers (with or without '+' prefix)</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>message</td>
                <td>string</td>
                <td>The message to send</td>
                <td>Yes</td>
              </tr>
            </tbody>
          </table>
          
          <h3>Integration Examples</h3>
          
          <div className="code-tabs">
            <div className="tabs-header">
              {Object.keys(examples).map(tab => (
                <button 
                  key={tab}
                  className={`tab-btn ${activeTab === tab ? 'active' : ''}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab === 'csharp' ? 'C#' : tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            
            <div className="code-example-container">
              <div className="code-actions">
                <button className="copy-code-btn" onClick={copyExample}>
                  <span className="copy-icon">📋</span> Copy
                </button>
              </div>
              <pre className="code-example">
                {examples[activeTab]}
              </pre>
            </div>
          </div>
          
          <h3>Example Responses</h3>
          <pre>
{`// Success
{
  "success": true,
  "results": [
    { "recipient": "123456789", "success": true },
    { "recipient": "987654321", "success": true }
  ],
  "recipients_total": 2,
  "recipients_success": 2,
  "message_segments": 1,
  "credits_used": 2,
  "credits_remaining": 88
}

// Error - Insufficient Credits
{
  "success": false,
  "error": "Insufficient credits",
  "credits_required": 5,
  "credits_available": 2
}

// Error - Authentication
{
  "success": false,
  "error": "Invalid API key"
}`}
          </pre>
          
          <div className="api-notes">
            <h3>Important Notes</h3>
            <ul>
              <li>Phone numbers can be provided with or without the '+' prefix (e.g., both '+37126123456' and '37126123456' are accepted).</li>
              <li>Each SMS segment costs 1 credit. Messages longer than 160 characters (or 70 for messages with special characters) will be sent as multiple segments.</li>
              <li>Each API request can have multiple recipients specified as a comma-separated list.</li>
              <li>The API will return the number of credits remaining after the transaction.</li>
              <li>Always check the 'success' field in the response to confirm the message was sent successfully.</li>
              <li>Authorization is done via Bearer token in the request header, not in the request body.</li>
            </ul>
          </div>
        </section>
      </div>
    </BaseModal>
  );
};

export default ApiDocsModal;