import React from 'react';
import BaseModal from './BaseModal';

const EmailSmsModal = ({ onClose, onModalClose }) => {
  return (
    <BaseModal
      onClose={onClose}
      onModalClose={onModalClose}
      title="Email-to-SMS Feature"
      maxWidth="700px"
    >
      <div className="email-sms-info">
        <div className="feature-explanation">
          <h3>How to Send SMS via Email</h3>
          
          <div className="step">
            <h4>1. Compose Your Email</h4>
            <p>
              Open your email client and compose a new email. Use the recipient's phone number 
              followed by <strong>@tonesms.com</strong> as the recipient's email address.
            </p>
            <p className="example">
              <strong>Example:</strong> <code>37026123456@tonesms.com</code>
            </p>
          </div>
          
          <div className="step">
            <h4>2. Set Your Sender Name</h4>
            <p>
              The subject of your email will be used as the sender name for the SMS.
              It will be truncated to a maximum of 11 characters.
            </p>
            <p className="example">
              <strong>Example:</strong> Subject <code>CompanyName</code> will appear as the sender name.
            </p>
            <p className="note">
              <strong>Note:</strong> If no subject is provided, your domain name will be used as the sender.
            </p>
          </div>
          
          <div className="step">
            <h4>3. Write Your SMS</h4>
            <p>
              Enter your message directly in the body of the email. This will be sent as the SMS content.
            </p>
            <p className="note">
              <strong>Note:</strong> Keep your message concise to fit SMS character limits.
            </p>
          </div>
          
          <div className="step">
            <h4>4. Send Your Email</h4>
            <p>
              Once your email is ready, send it. Our system will process it and deliver the 
              message as an SMS to the recipient's phone number.
            </p>
            <p className="important">
              <strong>Supported Senders:</strong> Ensure your email is sent from an authorized 
              domain. You can configure authorized domains in the Settings.
            </p>
          </div>
        </div>
        
        <div className="email-sms-note">
          <p>
            <strong>Note:</strong> Each SMS sent via email will deduct credits from your account 
            just like regular SMS. The sender name for these messages will be your email subject or your authorized domain.
          </p>
        </div>
      </div>
    </BaseModal>
  );
};

export default EmailSmsModal;