import React from 'react';
import { toast } from 'react-toastify';
import { paymentService } from '../../services/api';

const SavedPaymentMethods = ({ userId, onSelectSavedMethod, onAddNewCard, paymentMethods }) => {
  // We'll now receive the payment methods directly from the parent component,
  // eliminating the need for another API call
  
  const handleSelectCard = (paymentMethod) => {
    onSelectSavedMethod(paymentMethod);
  };

  const handleDeleteCard = async (paymentMethodId) => {
    if (window.confirm('Are you sure you want to remove this payment method?')) {
      try {
        const response = await paymentService.deleteSavedPaymentMethod(paymentMethodId);
        
        if (response.success) {
          // Let the parent component know to refresh payment methods
          toast.success('Payment method removed successfully');
          // Instead of modifying state here, we'll trigger a refresh in the parent
          onSelectSavedMethod(null);
        } else {
          toast.error(response.error || 'Failed to remove payment method');
        }
      } catch (err) {
        toast.error('An error occurred');
        console.error('Error deleting payment method:', err);
      }
    }
  };

  if (!paymentMethods || paymentMethods.length === 0) {
    return (
      <div className="no-saved-methods">
        <p>You don't have any saved payment methods.</p>
        <button className="add-card-btn" onClick={onAddNewCard}>
          + Add New Payment Method
        </button>
      </div>
    );
  }

  return (
    <div className="saved-payment-methods">
      <h3>Saved Payment Methods</h3>
      
      <ul className="payment-method-list">
        {paymentMethods.map(method => (
          <li key={method.id} className="payment-method-item">
            <div className="payment-method-info">
              <div className="card-brand-icon">
                {method.card.brand === 'visa' && '💳 Visa'}
                {method.card.brand === 'mastercard' && '💳 Mastercard'}
                {method.card.brand === 'amex' && '💳 Amex'}
                {method.card.brand === 'discover' && '💳 Discover'}
                {(method.card.brand !== 'visa' && 
                  method.card.brand !== 'mastercard' && 
                  method.card.brand !== 'amex' && 
                  method.card.brand !== 'discover') && '💳 Card'}
              </div>
              <div className="card-details">
                <span className="card-last4">•••• {method.card.last4}</span>
                <span className="card-expiry">Expires {method.card.exp_month}/{method.card.exp_year.toString().substr(-2)}</span>
              </div>
            </div>
            <div className="payment-method-actions">
              <button 
                className="select-btn"
                onClick={() => handleSelectCard(method)}
              >
                Use This Card
              </button>
              <button 
                className="delete-btn"
                onClick={() => handleDeleteCard(method.id)}
              >
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>
      
      <div className="add-new-payment-method">
        <button className="add-card-btn" onClick={onAddNewCard}>
          + Add New Payment Method
        </button>
      </div>
    </div>
  );
};

export default SavedPaymentMethods;