import React from 'react';

/**
 * A component to display message credit estimation with improved styling
 * 
 * @param {Object} props - Component props
 * @param {number} props.credits - The number of credits required
 * @param {number} props.segments - The number of message segments
 * @param {number} props.characters - The number of characters in the message
 * @param {boolean} props.hasSpecialChars - Whether message contains special characters
 */
const CreditsEstimate = ({ credits, segments, characters, hasSpecialChars }) => {
  // Determine if this is a multi-segment message that needs a warning
  const isMultiSegment = segments > 1;
  
  // Don't render anything if there's no meaningful information to show
  if (credits <= 0 || characters <= 0) {
    return null;
  }
  
  return (
    <div className={`credits-estimate ${isMultiSegment ? 'warning' : ''}`}>
      <div className="credits-estimate-content">
        <div className="estimate-main">
          <strong>Estimated credits: {credits}</strong>
        </div>
        <div className="estimate-details">
          <span className="estimate-detail">
            {characters} characters
            {hasSpecialChars ? ' (contains special characters)' : ''} 
            {' • '}
            {isMultiSegment 
              ? `Will be sent as ${segments} message${segments > 1 ? 's' : ''}` 
              : 'Standard SMS'
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreditsEstimate;