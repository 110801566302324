import React from 'react';

const Navbar = ({ activeTab, onNavItemClick }) => {
  const navItems = [
    { id: 'sms', label: 'Send SMS' },
    { id: 'api', label: 'API Documentation' },
    { id: 'history', label: 'Usage History' },
    { id: 'email-sms', label: 'Email to SMS' }
  ];

  return (
    <nav>
      <ul>
        {navItems.map(item => (
          <li 
            key={item.id}
            className={activeTab === item.id ? 'active' : ''}
            onClick={() => onNavItemClick(item.id)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;