import React from 'react';

const Privacy = () => {
  return (
    <div className="legal-page">
      <h1>Privacy Policy</h1>
      <div className="legal-content">
        <h2>1. Information We Collect</h2>
        <p>We collect information you provide when you register for and use the Service, including contact information, payment details, and the content of messages sent through our platform.</p>
        
        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to provide, maintain, and improve the Service, to process payments, to communicate with you, and to comply with legal obligations.</p>
        
        <h2>3. Information Sharing</h2>
        <p>We do not sell your personal information. We may share your information with third-party service providers who assist us in operating our business, processing payments, or servicing you.</p>
        
        <h2>4. Data Security</h2>
        <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or destruction.</p>
        
        <h2>5. Data Retention</h2>
        <p>We retain your personal information for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
        
        <h2>6. Your Rights</h2>
        <p>Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, or delete your data.</p>
        
        <h2>7. Cookies and Tracking Technologies</h2>
        <p>We use cookies and similar tracking technologies to collect information about your browsing activities and to remember your preferences.</p>
        
        <h2>8. Children's Privacy</h2>
        <p>The Service is not intended for individuals under the age of 18. We do not knowingly collect personal information from children.</p>
        
        <h2>9. Changes to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        
        <h2>10. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at privacy@tonesms.com.</p>
      </div>
    </div>
  );
};

export default Privacy;