import React from 'react';

const Footer = ({ onLinkClick }) => {
  return (
    <footer>
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} ToneSMS. Made with ❤️ in 🇪🇺.</p>
        <div className="footer-links">
          <a href="#terms" onClick={(e) => onLinkClick(e, 'terms')}>Terms of Service</a>
          <a href="#privacy" onClick={(e) => onLinkClick(e, 'privacy')}>Privacy Policy</a>
          <a href="#contact" onClick={(e) => onLinkClick(e, 'contact')}>Contact Us</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;