import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BaseModal from './BaseModal';
import { authService } from '../../services/api';
import { handleEnterKeySubmit } from '../../utils/helpers';
import { useUser } from '../../contexts/UserContext';

const EmailVerificationModal = ({ onClose, onModalClose, onVerificationSuccess }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { updateUserData } = useUser();

  const handleVerifyEmail = async () => {
    if (!verificationCode) {
      toast.error('Please enter the verification code');
      return;
    }
    
    setLoading(true);
    
    try {
      const result = await authService.verifyEmailChange(verificationCode);
      
      if (result.success) {
        // Update the userData context with new email
        const updatedData = await authService.refreshUserData();
        if (updatedData) {
          updateUserData(updatedData);
        }
        
        // Reset state
        setVerificationCode('');
        
        // Show success message and close the modal
        toast.success('Email updated successfully');
        
        // Call the success callback
        if (onVerificationSuccess) {
          onVerificationSuccess();
        }
      }
    } catch (error) {
      toast.error(`Verification error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      onClose={onClose}
      onModalClose={onModalClose}
      title="Verify Email Change"
      maxWidth="500px"
    >
      <div className="email-verification-form">
        <p>A verification code has been sent to your new email address</p>
        
        <div className="form-group">
          <label htmlFor="verification-code">Verification Code:</label>
          <input
            type="text"
            id="verification-code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Enter the 6-digit code"
            onKeyDown={(e) => handleEnterKeySubmit(e, handleVerifyEmail)}
          />
        </div>
        
        <button 
          className="verify-btn" 
          onClick={handleVerifyEmail}
          disabled={loading}
        >
          {loading ? 'Verifying...' : 'Verify Email'}
        </button>
      </div>
    </BaseModal>
  );
};

export default EmailVerificationModal;