// Updates to App.js to include OnboardingModal and ReferralModal
import React, { useState, useEffect, useRef } from 'react';
import './styles/main.css';
import './styles/referral.css'; // Import referral styles
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authService } from './services/api';
import referralService from './services/referralService'; // Import referral service

// Layout components
import Header from './components/layout/Header';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

// Main content components
import SendSmsForm from './components/sms/SendSmsForm';

// Modal components
import ApiDocsModal from './components/modals/ApiDocsModal';
import HistoryModal from './components/modals/HistoryModal';
import PaymentModal from './components/modals/PaymentModal';
import AuthModal from './components/modals/AuthModal';
import SettingsModal from './components/modals/SettingsModal';
import EmailSmsModal from './components/modals/EmailSmsModal';
import EmailVerificationModal from './components/modals/EmailVerificationModal';
import ContactModal from './components/modals/ContactModal';
import PrivacyModal from './components/modals/PrivacyModal';
import TermsModal from './components/modals/TermsModal';
import OnboardingModal from './components/modals/OnboardingModal';
import ReferralModal from './components/modals/ReferralModal'; // Import ReferralModal

// Context provider
import { UserProvider } from './contexts/UserContext';

// Low credits handler
import LowCreditsHandler from './components/payment/LowCreditsHandler';

// Microsoft Clarity
import Clarity from '@microsoft/clarity';
const clarityProjectId = "qm5kp3v8n5"

function App() {
  // App state
  const [activeTab, setActiveTab] = useState('sms');
  
  // Modal visibility states
  const [showHistory, setShowHistory] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showApiDocs, setShowApiDocs] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showEmailSms, setShowEmailSms] = useState(false);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showReferral, setShowReferral] = useState(false);
  
  // Authentication and user state
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [authInitialTab, setAuthInitialTab] = useState('login');
  const [userData, setUserData] = useState({
    id: null,
    credits: 0,
    email: '',
    phoneNumber: '',
    company: {
      name: '',
      address: '',
      vatNumber: '',
      contactPerson: ''
    },
    authorizedEmailDomains: [''],
    apiKey: '',
    autoTopUp: false,
    referralCode: '', // Track user's referral code
    referralsCount: 0 // Track number of successful referrals
  });

  // Reference for low credits handler
  const topUpModalTriggerRef = useRef(null);

  // Check authentication on page load
  useEffect(() => {
    const checkAuthAndLoadData = async () => {
      if (authService.isAuthenticated()) {
        const data = await authService.refreshUserData();
        if (data) {
          setIsAuthorized(true);
          updateUserData(data);
          
          // Check if this is a new user (just registered and verified by OTP)
          // Or if the user has a pending onboarding process from a previous session
          const onboardingPending = localStorage.getItem('onboarding_pending');
          
          if ((data.credits === 0 && data.email_verified === false) || onboardingPending === 'true') {
            setShowOnboarding(true);
          }
          
          const query = new URLSearchParams(window.location.search);
          if (query.get('payment_intent')) {
            setShowPayment(true);
          }
        }
      }
    };
    
    checkAuthAndLoadData();
  }, []);

  // Check for referral code in URL
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const referralCode = queryParams.get('ref');
    
    if (referralCode) {
      // Store referral code in localStorage to use during registration
      localStorage.setItem('referral_code', referralCode);
      
      // Remove referral code from URL to keep it clean
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      
      // If not logged in, show the login/registration modal
      if (!isAuthorized) {
        setShowAuth(true);
        setAuthInitialTab('register');
      }
    }
  }, [isAuthorized]);

  // Update user data helper
  const updateUserData = (data) => {
    setUserData({
      id: data.id,
      credits: data.credits,
      email: data.email,
      phoneNumber: data.phone,
      company: data.company || {
        name: '',
        address: '',
        vatNumber: '',
        contactPerson: ''
      },
      authorizedEmailDomains: data.authorized_email_domains || [''],
      apiKey: data.api_key || '',
      emailVerified: data.email_verified || false,
      autoTopUp: data.auto_topup || false,
      autoTopUpAmount: data.auto_topup_amount || 10,
      referralCode: data.referral_code || '',
      referralsCount: data.referrals_count || 0
    });
  };

  // Handle modals
  const handleModalClose = (e, setShowFunction) => {
    if (e.target.classList.contains('modal')) {
      setShowFunction(false);
    }
  };

  // Handle nav item clicks
  const handleNavItemClick = (tabName) => {
    if (tabName === 'api') {
      setShowApiDocs(true);
    } else if (tabName === 'history') {
      setShowHistory(true);
    } else if (tabName === 'email-sms') {
      setShowEmailSms(true);
    } else if (tabName === 'referral') { // New option for referral program
      setShowReferral(true);
    } else {
      setActiveTab(tabName);
    }
  };

  useEffect(() => {
    window.handleFooterLinkClick = handleFooterLinkClick;
    
    return () => {
      delete window.handleFooterLinkClick;
    };
  }, []);

  // Handle footer link clicks
  const handleFooterLinkClick = (e, pageType) => {
    e.preventDefault();
    
    // Close all other modals
    setShowHistory(false);
    setShowPayment(false);
    setShowAuth(false);
    setShowApiDocs(false);
    setShowSettings(false);
    setShowEmailSms(false);
    setShowEmailVerification(false);
    setShowOnboarding(false);
    setShowReferral(false); // Close referral modal
    
    // Open the requested page
    if (pageType === 'terms') {
      setShowTerms(true);
      setShowPrivacy(false);
      setShowContact(false);
    } else if (pageType === 'privacy') {
      setShowTerms(false);
      setShowPrivacy(true);
      setShowContact(false);
    } else if (pageType === 'contact') {
      setShowTerms(false);
      setShowPrivacy(false);
      setShowContact(true);
    }
  };

  // Auth related handlers
  const handleLoginSuccess = async (userData) => {
    setIsAuthorized(true);
    updateUserData(userData);
    setShowAuth(false);
    
    // Check if this is a new user
    if (userData.credits === 0 && !userData.emailVerified) {
      setShowOnboarding(true);
    }
    
    // Check if there's a stored referral code to apply
    const referralCode = localStorage.getItem('referral_code');
    if (referralCode && userData.id) {
      try {
        await referralService.applyReferralCode(referralCode, userData.id);
        // Clear the referral code from localStorage
        localStorage.removeItem('referral_code');
        // Store the code to later complete the referral
        localStorage.setItem('referred_by', referralCode);
      } catch (error) {
        console.error('Error applying referral code:', error);
      }
    }
  };

  const handleLogout = () => {
    authService.logout();
    setIsAuthorized(false);
    setUserData({
      id: null,
      credits: 0,
      email: '',
      phoneNumber: '',
      company: {
        name: '',
        address: '',
        vatNumber: '',
        contactPerson: ''
      },
      authorizedEmailDomains: [''],
      apiKey: '',
      autoTopUp: false,
      referralCode: '',
      referralsCount: 0
    });
  };

  // Handle low credit situations
  const handleLowCreditsDetected = (requiredCredits) => {
    if (topUpModalTriggerRef.current) {
      topUpModalTriggerRef.current.showTopUpModal();
    }
  };

  // Context value for user state
  const userContextValue = {
    isAuthorized,
    userData,
    updateUserData,
    setShowAuth,
    setShowSettings,
    setShowPayment,
    setShowReferral, // Add referral modal setter
    handleLogout,
    handleLowCreditsDetected
  };

  Clarity.init(clarityProjectId);

  return (
    <UserProvider value={userContextValue}>
      <div className="app-container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        
        <Header
          onReferralClick={() => setShowReferral(true)} // Add handler for referral button click
        />
        
        <Navbar
          activeTab={activeTab}
          onNavItemClick={handleNavItemClick}
        />
        
        <LowCreditsHandler
          includeWarning={isAuthorized}
          warningThreshold={50}
          triggerRef={topUpModalTriggerRef}
        >
          {activeTab === 'sms' && (
            <SendSmsForm />
          )}
        </LowCreditsHandler>
        
        {/* Modals */}
        {showApiDocs && (
          <ApiDocsModal 
            onClose={() => setShowApiDocs(false)} 
            onModalClose={(e) => handleModalClose(e, setShowApiDocs)} 
          />
        )}
        
        {showHistory && (
          <HistoryModal 
            onClose={() => setShowHistory(false)} 
            onModalClose={(e) => handleModalClose(e, setShowHistory)} 
          />
        )}
        
        {showPayment && (
          <PaymentModal 
            onClose={() => setShowPayment(false)} 
            onModalClose={(e) => handleModalClose(e, setShowPayment)} 
          />
        )}
        
        {showAuth && (
          <AuthModal 
            onClose={() => setShowAuth(false)} 
            onModalClose={(e) => handleModalClose(e, setShowAuth)} 
            onLoginSuccess={handleLoginSuccess}
            initialTab={authInitialTab}
          />
        )}
        
        {showSettings && (
          <SettingsModal 
            onClose={() => setShowSettings(false)} 
            onModalClose={(e) => handleModalClose(e, setShowSettings)} 
            onEmailVerificationNeeded={() => setShowEmailVerification(true)}
          />
        )}
        
        {showEmailSms && (
          <EmailSmsModal 
            onClose={() => setShowEmailSms(false)} 
            onModalClose={(e) => handleModalClose(e, setShowEmailSms)} 
          />
        )}
        
        {showEmailVerification && (
          <EmailVerificationModal 
            onClose={() => setShowEmailVerification(false)} 
            onModalClose={(e) => handleModalClose(e, setShowEmailVerification)} 
            onVerificationSuccess={() => {
              setShowEmailVerification(false);
              setShowSettings(false);
            }}
          />
        )}
        
        {showOnboarding && (
          <OnboardingModal 
            onClose={() => setShowOnboarding(false)} 
            onModalClose={(e) => handleModalClose(e, setShowOnboarding)} 
          />
        )}
        
        {showReferral && (
          <ReferralModal
            onClose={() => setShowReferral(false)}
            onModalClose={(e) => handleModalClose(e, setShowReferral)}
          />
        )}
        
        {showContact && (
          <ContactModal 
            onClose={() => setShowContact(false)} 
            onModalClose={(e) => handleModalClose(e, setShowContact)} 
          />
        )}
        
        {showPrivacy && (
          <PrivacyModal 
            onClose={() => setShowPrivacy(false)} 
            onModalClose={(e) => handleModalClose(e, setShowPrivacy)} 
          />
        )}
        
        {showTerms && (
          <TermsModal 
            onClose={() => setShowTerms(false)} 
            onModalClose={(e) => handleModalClose(e, setShowTerms)} 
          />
        )}
        
        <Footer onLinkClick={handleFooterLinkClick} />
      </div>
    </UserProvider>
  );
}

export default App;