/**
 * Utility to handle form submission with Enter key
 * 
 * This hook can be used in any form component to enable 
 * consistent Enter key behavior.
 */
import { useEffect } from 'react';

/**
 * Handle Enter key press in a form to trigger submit action
 * @param {Function} submitAction - The function to call when Enter is pressed
 * @param {Array} dependencies - Dependencies array, similar to useEffect
 * @param {string} formId - Optional ID of the form element (if null, handles all inputs)
 */
export const useEnterKeySubmit = (submitAction, dependencies = [], formId = null) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if the key press is Enter
      if (e.key === 'Enter' && !e.shiftKey) {
        // If in a textarea, Enter should add a new line unless Ctrl/Cmd is pressed
        if (e.target.tagName === 'TEXTAREA' && !e.ctrlKey && !e.metaKey) {
          return;
        }
        
        // If the element is a button, don't handle (let the button's click event fire)
        if (e.target.tagName === 'BUTTON') {
          return;
        }
        
        // Prevent default form submission
        e.preventDefault();
        
        // Call the submit action
        submitAction();
      }
    };
    
    // Add event listener to the specific form or document
    const element = formId ? document.getElementById(formId) : document;
    if (element) {
      element.addEventListener('keydown', handleKeyDown);
    }
    
    // Cleanup
    return () => {
      if (element) {
        element.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, dependencies); // Re-attach when dependencies change
};

/**
 * Helper function to handle Enter key press in a single input
 * @param {Event} e - The keydown event
 * @param {Function} action - The function to call when Enter is pressed
 */
export const handleEnterKeySubmit = (e, action) => {
  if (e.key === 'Enter' && !e.shiftKey) {
    if (e.target.tagName !== 'TEXTAREA' || (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      action();
    }
  }
};

export default { useEnterKeySubmit, handleEnterKeySubmit };