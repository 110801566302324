import React, { useState } from 'react';

const ReferralInvite = ({ onSubmit, loading }) => {
  const [emails, setEmails] = useState(['']);
  const [errors, setErrors] = useState([]);
  
  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
    
    // Clear any error for this field
    if (errors[index]) {
      const newErrors = [...errors];
      newErrors[index] = '';
      setErrors(newErrors);
    }
  };
  
  const addEmailField = () => {
    setEmails([...emails, '']);
    setErrors([...errors, '']);
  };
  
  const removeEmailField = (index) => {
    if (emails.length <= 1) return;
    
    const newEmails = emails.filter((_, i) => i !== index);
    const newErrors = errors.filter((_, i) => i !== index);
    
    setEmails(newEmails);
    setErrors(newErrors);
  };
  
  const validateEmails = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = emails.map(email => {
      if (!email.trim()) {
        return 'Email is required';
      }
      if (!emailRegex.test(email)) {
        return 'Invalid email format';
      }
      return '';
    });
    
    setErrors(newErrors);
    return newErrors.every(error => !error);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateEmails()) {
      // Filter out any empty emails
      const validEmails = emails.filter(email => email.trim());
      onSubmit(validEmails);
    }
  };
  
  return (
    <div className="referral-invite">
      <h3>Invite friends via email</h3>
      <p>Enter your friends' email addresses and we'll send them an invitation to join ToneSMS with your referral code.</p>
      
      <form onSubmit={handleSubmit}>
        {emails.map((email, index) => (
          <div key={index} className="email-input-group">
            <div className="email-input-container">
              <input
                type="text"
                placeholder="friend@example.com"
                value={email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                disabled={loading}
                className={errors[index] ? 'input-error' : ''}
              />
              
              <button
                type="button"
                className="remove-email-btn"
                onClick={() => removeEmailField(index)}
                disabled={loading || emails.length <= 1}
              >
                &times;
              </button>
            </div>
            
            {errors[index] && (
              <div className="error-message">{errors[index]}</div>
            )}
          </div>
        ))}
        
        <div className="invite-actions">
          <button
            type="button"
            className="add-email-btn"
            onClick={addEmailField}
            disabled={loading}
          >
            + Add Another Email
          </button>
          
          <button
            type="submit"
            className="submit-invite-btn"
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Invitations'}
          </button>
        </div>
      </form>
      
      <div className="invite-info">
        <p>Don't worry! We'll never use these emails for any purpose other than sending this invitation.</p>
      </div>
    </div>
  );
};

export default ReferralInvite;