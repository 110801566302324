import React from 'react';

/**
 * Modal to select phone number column from CSV
 */
const CSVSelector = ({ csvColumns, csvData, onSelect }) => {
  return (
    <div className="csv-modal">
      <div className="csv-modal-content">
        <h3>Select Phone Number Column</h3>
        <p>Choose which column contains the recipient phone numbers:</p>
        
        <div className="csv-columns-list">
          {csvColumns.map((column, index) => (
            <button
              key={index}
              type="button"
              className="column-select-btn"
              onClick={() => onSelect(index)}
            >
              {column}
            </button>
          ))}
        </div>
        
        <div className="csv-preview">
          <h4>CSV Preview</h4>
          <div className="csv-table-container">
            <table className="csv-table">
              <thead>
                <tr>
                  {csvColumns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData && csvData.slice(0, 3).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {csvColumns.map((column, colIndex) => (
                      <td key={colIndex}>{row[column]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <p className="csv-instructions">
            After selecting the phone number column, other columns will be available as 
            personalization variables using <code>{"{{column_name}}"}</code> syntax in your message.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CSVSelector;