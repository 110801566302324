import React from 'react';

const Terms = () => {
  return (
    <div className="legal-page">
      <h1>Terms of Service</h1>
      <div className="legal-content">
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using the ToneSMS service ("Service"), you agree to be bound by these Terms of Service ("Terms"). The Service is owned and operated by ToneSMS ("we," "us," or "our").</p>
        
        <h2>2. Description of Service</h2>
        <p>ToneSMS provides a platform for sending SMS messages to recipients. The Service allows users to send text messages to one or multiple recipients.</p>
        
        <h2>3. Registration and Account Security</h2>
        <p>To use the Service, you must register and create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
        
        <h2>4. Payment Terms</h2>
        <p>ToneSMS operates on a credit-based system. One credit equals one SMS message segment. Credits can be purchased through our platform. All purchases are final and non-refundable.</p>
        
        <h2>5. Use of Service</h2>
        <p>You agree not to use the Service for any illegal or unauthorized purpose. You must comply with all applicable laws regarding the transmission of technical data.</p>
        
        <h2>6. Message Content</h2>
        <p>You are solely responsible for the content of messages sent through the Service. You agree not to use the Service to send spam, unsolicited messages, or content that is illegal, harassing, or otherwise objectionable.</p>
        
        <h2>7. Termination</h2>
        <p>We reserve the right to terminate your access to the Service for any reason, including but not limited to a violation of these Terms.</p>
        
        <h2>8. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>
        
        <h2>9. Limitation of Liability</h2>
        <p>ToneSMS shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the Service.</p>
        
        <h2>10. Governing Law</h2>
        <p>These Terms shall be governed by the laws of Europe, without regard to its conflict of law provisions.</p>
        
        <h2>11. Contact Information</h2>
        <p>If you have any questions about these Terms, please contact us at support@tonesms.com.</p>
      </div>
    </div>
  );
};

export default Terms;