import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserContext } from '../../contexts/UserContext';
import { paymentService, authService } from '../../services/api';
import { toast } from 'react-toastify';
import StripeCheckout from './StripeCheckout';
import SavedPaymentMethods from './SavedPaymentMethods';

// Load stripe outside of component to avoid recreating
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const QuickTopUpModal = ({ onClose, onModalClose, requiredCredits = 0 }) => {
  const { userData, updateUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [savedMethods, setSavedMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [showSavedMethods, setShowSavedMethods] = useState(true);
  const [selectedAmount, setSelectedAmount] = useState(
    requiredCredits > 0 ? Math.ceil(requiredCredits * 0.1) : 10
  );
  const [autoTopUpEnabled, setAutoTopUpEnabled] = useState(false);
  const [dataInitialized, setDataInitialized] = useState(false);

  // Predefined amounts
  const quickAmounts = [5, 10, 20, 50, 100];

  // Initialize modal data - only run once when the modal opens
  useEffect(() => {
    const initializeData = async () => {
      if (dataInitialized) return;
      
      setLoading(true);
      try {
        // Get latest user data
        const freshUserData = await authService.refreshUserData();
        if (freshUserData) {
          console.log('Fresh user data for modal initialization:', freshUserData);
          updateUserData(freshUserData);
          
          // Set auto-topup state
          setAutoTopUpEnabled(!!freshUserData.auto_topup);
          
          // Set amount if auto-topup is enabled
          if (freshUserData.auto_topup_amount && freshUserData.auto_topup) {
            setSelectedAmount(parseFloat(freshUserData.auto_topup_amount));
          }
          
          // Fetch saved payment methods
          const response = await paymentService.getSavedPaymentMethods(freshUserData.id);
          console.log('Saved payment methods:', response);
          
          if (response.success && response.payment_methods && response.payment_methods.length > 0) {
            setSavedMethods(response.payment_methods);
            
            // Find auto-topup payment method if set
            if (freshUserData.auto_topup_payment_method) {
              const autoTopupMethod = response.payment_methods.find(
                method => method.id === freshUserData.auto_topup_payment_method
              );
              
              if (autoTopupMethod) {
                setSelectedMethod(autoTopupMethod);
              } else {
                // Find default payment method, or use the first one
                const defaultMethod = response.payment_methods.find(m => m.is_default) || response.payment_methods[0];
                setSelectedMethod(defaultMethod);
              }
            } else {
              // Find default payment method, or use the first one
              const defaultMethod = response.payment_methods.find(m => m.is_default) || response.payment_methods[0];
              setSelectedMethod(defaultMethod);
            }
            
            setShowSavedMethods(true);
          } else {
            console.log('No saved payment methods found');
            setShowSavedMethods(false);
          }
          
          // Mark data as initialized to prevent further refreshes
          setDataInitialized(true);
        }
      } catch (error) {
        console.error('Error initializing modal data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    initializeData();
  }, [userData.id, updateUserData, dataInitialized]);

  // Update autoTopUpEnabled when userData changes (but don't trigger API calls)
  useEffect(() => {
    if (userData && userData.auto_topup !== undefined && dataInitialized) {
      setAutoTopUpEnabled(!!userData.auto_topup);
    }
  }, [userData.auto_topup, dataInitialized]);

  const handlePaymentSuccess = async (result) => {
    // Refresh user data to get the latest
    const freshData = await authService.refreshUserData();
    if (freshData) {
      updateUserData(freshData);
    }
    
    // Close modal and notify parent component
    onClose(true, result.credits_added);
  };

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
  };

  const handleCustomAmount = (e) => {
    const amount = parseFloat(e.target.value);
    if (!isNaN(amount) && amount >= 1) {
      setSelectedAmount(amount);
    }
  };

  const handleShowNewCardForm = () => {
    setShowSavedMethods(false);
    setSelectedMethod(null);
  };

  const handleSelectSavedMethod = (method) => {
    setSelectedMethod(method);
    setShowSavedMethods(true);
  };
  
  const handleToggleAutoTopUp = async () => {
    if (!selectedMethod && showSavedMethods) {
      toast.error('Please select a payment method to enable auto top-up');
      return;
    }
    
    setLoading(true);
    try {
      const paymentMethodId = selectedMethod ? selectedMethod.id : null;
      
      // Toggle from current state
      const newAutoTopUpState = !autoTopUpEnabled;
      
      console.log('Toggling auto top-up:', {
        currentState: autoTopUpEnabled,
        newState: newAutoTopUpState,
        amount: selectedAmount,
        paymentMethodId
      });
      
      const response = await paymentService.toggleAutoTopUp(
        userData.id, 
        newAutoTopUpState,
        selectedAmount,
        paymentMethodId
      );
      
      if (response.success) {
        // Update local state immediately (don't wait for refresh)
        setAutoTopUpEnabled(newAutoTopUpState);
        
        // Update user data in context with the new auto-topup settings
        updateUserData({
          ...userData,
          auto_topup: newAutoTopUpState,
          auto_topup_amount: selectedAmount,
          auto_topup_payment_method: newAutoTopUpState ? paymentMethodId : null
        });
        
        toast.success(autoTopUpEnabled ? 'Auto top-up disabled' : 'Auto top-up enabled');
      } else {
        toast.error(response.error || 'Failed to update auto top-up settings');
      }
    } catch (error) {
      console.error('Error toggling auto top-up:', error);
      toast.error('Error updating auto top-up settings, do you have a saved card?');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal quick-topup-modal" onClick={onModalClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Quick Top-Up</h2>
          <button className="close-btn" onClick={() => onClose(false)}>&times;</button>
        </div>
        
        <div className="modal-body">
          {requiredCredits > 0 && (
            <div className="credits-needed-alert">
              <p>You need <strong>{requiredCredits} more credits</strong> to complete this operation.</p>
            </div>
          )}
          
          <div className="amount-selection">
            <h3>Select Amount</h3>
            <div className="quick-amount-buttons">
              {quickAmounts.map(amount => (
                <button
                  key={amount}
                  className={`amount-btn ${selectedAmount === amount ? 'selected' : ''}`}
                  onClick={() => handleAmountSelect(amount)}
                >
                  ${amount}
                </button>
              ))}
            </div>
            
            <div className="custom-amount">
              <label>Custom Amount:</label>
              <div className="custom-amount-input">
                <span className="currency-symbol">$</span>
                <input
                  type="number"
                  min="1"
                  step="0.01"
                  value={selectedAmount}
                  onChange={handleCustomAmount}
                />
              </div>
            </div>
          </div>
          
          <div className="payment-method-selection">
            {savedMethods.length > 0 && (
              <div className="payment-method-toggle">
                <button
                  className={`toggle-btn ${showSavedMethods ? 'active' : ''}`}
                  onClick={() => setShowSavedMethods(true)}
                >
                  Saved Cards
                </button>
                <button
                  className={`toggle-btn ${!showSavedMethods ? 'active' : ''}`}
                  onClick={handleShowNewCardForm}
                >
                  New Card
                </button>
              </div>
            )}
            
            <Elements stripe={stripePromise}>
            {showSavedMethods && savedMethods.length > 0 ? (
                <div className="saved-methods-container">
                    <SavedPaymentMethods
                    userId={userData.id}
                    onSelectSavedMethod={handleSelectSavedMethod}
                    onAddNewCard={handleShowNewCardForm}
                    paymentMethods={savedMethods} // Pass payment methods directly
                    />
                    
                    {selectedMethod && (
                    <StripeCheckout
                        amount={selectedAmount}
                        userId={userData.id}
                        setLoading={setLoading}
                        returnUrl={window.location.href}
                        disabled={loading}
                        savedPaymentMethod={selectedMethod}
                        onPaymentSuccess={handlePaymentSuccess}
                    />
                    )}
                </div>
                ) : (
                <StripeCheckout
                    amount={selectedAmount}
                    userId={userData.id}
                    setLoading={setLoading}
                    returnUrl={window.location.href}
                    disabled={loading}
                    onPaymentSuccess={handlePaymentSuccess}
                />
                )}
            </Elements>
            
            {(savedMethods.length > 0 || !showSavedMethods) && (
              <div className="low-credits-auto-topup">
                <h3>Auto Top-Up</h3>
                <div className="auto-topup-option">
                  <label htmlFor="auto-topup-checkbox" className="switch">
                    <input 
                      type="checkbox" 
                      id="auto-topup-checkbox"
                      checked={autoTopUpEnabled}
                      onChange={handleToggleAutoTopUp}
                      disabled={loading || (showSavedMethods && !selectedMethod)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <div className="auto-topup-description">
                    <p>Automatically add credits when your balance falls below 20</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickTopUpModal;