// services/draftMessageService.js
// This service handles automatic saving and loading of draft messages

const DRAFT_KEY_PREFIX = 'tone_sms_draft_';

/**
 * Service to manage automatic draft saving and retrieval for SMS forms
 */
const draftMessageService = {
  /**
   * Save the current form state as a draft
   * @param {Object} formData - The form data to save (sender, receivers, message)
   * @param {String|null} userId - Optional user ID for authenticated users
   */
  saveDraft: (formData, userId = null) => {
    // Create a unique key based on whether user is authenticated or not
    const storageKey = userId 
      ? `${DRAFT_KEY_PREFIX}auth_${userId}` 
      : `${DRAFT_KEY_PREFIX}unauth`;
    
    // Add timestamp to know when draft was last saved
    const draftData = {
      ...formData,
      lastSaved: new Date().toISOString()
    };
    
    try {
      localStorage.setItem(storageKey, JSON.stringify(draftData));
      return true;
    } catch (error) {
      console.error('Error saving draft message:', error);
      return false;
    }
  },

  /**
   * Load a saved draft message
   * @param {String|null} userId - Optional user ID for authenticated users
   * @returns {Object|null} The saved form data or null if no draft exists
   */
  loadDraft: (userId = null) => {
    // Create a unique key based on whether user is authenticated or not
    const storageKey = userId 
      ? `${DRAFT_KEY_PREFIX}auth_${userId}` 
      : `${DRAFT_KEY_PREFIX}unauth`;
    
    try {
      const savedDraft = localStorage.getItem(storageKey);
      
      if (!savedDraft) {
        return null;
      }
      
      return JSON.parse(savedDraft);
    } catch (error) {
      console.error('Error loading draft message:', error);
      return null;
    }
  },

  /**
   * Clear a saved draft message
   * @param {String|null} userId - Optional user ID for authenticated users
   */
  clearDraft: (userId = null) => {
    const storageKey = userId 
      ? `${DRAFT_KEY_PREFIX}auth_${userId}` 
      : `${DRAFT_KEY_PREFIX}unauth`;
    
    try {
      localStorage.removeItem(storageKey);
      return true;
    } catch (error) {
      console.error('Error clearing draft message:', error);
      return false;
    }
  },

  /**
   * Transfer drafts from unauthenticated to authenticated state after login
   * @param {String} userId - The authenticated user ID
   */
  transferDrafts: (userId) => {
    try {
      // Check if there are unauthenticated drafts
      const unauthDraft = localStorage.getItem(`${DRAFT_KEY_PREFIX}unauth`);
      
      if (unauthDraft) {
        // Save the draft to the authenticated user
        localStorage.setItem(`${DRAFT_KEY_PREFIX}auth_${userId}`, unauthDraft);
        
        // Clear the unauthenticated draft
        localStorage.removeItem(`${DRAFT_KEY_PREFIX}unauth`);
      }
      
      return true;
    } catch (error) {
      console.error('Error transferring draft messages:', error);
      return false;
    }
  }
};

export default draftMessageService;