import React from 'react';
import { FaCheckCircle, FaHourglassHalf, FaCoins } from 'react-icons/fa';

const ReferralStats = ({ stats, loading }) => {
  // Format date helper
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };
  
  if (loading) {
    return (
      <div className="referral-stats loading">
        <div className="spinner"></div>
        <p>Loading your referral statistics...</p>
      </div>
    );
  }
  
  if (!stats) {
    return (
      <div className="referral-stats empty">
        <p>Unable to load statistics. Please try again later.</p>
      </div>
    );
  }
  
  return (
    <div className="referral-stats">
      <div className="stats-summary">
        <div className="stat-card">
          <div className="stat-icon">
            <FaCoins />
          </div>
          <div className="stat-content">
            <div className="stat-value">{stats.total_credits_earned}</div>
            <div className="stat-label">Credits Earned</div>
          </div>
        </div>
        
        <div className="stat-card">
          <div className="stat-icon">
            <FaCheckCircle />
          </div>
          <div className="stat-content">
            <div className="stat-value">{stats.referrals_count}</div>
            <div className="stat-label">Successful Referrals</div>
          </div>
        </div>
        
        <div className="stat-card">
          <div className="stat-icon">
            <FaHourglassHalf />
          </div>
          <div className="stat-content">
            <div className="stat-value">{stats.pending_referrals}</div>
            <div className="stat-label">Pending Referrals</div>
          </div>
        </div>
      </div>
      
      <div className="referrals-table-container">
        <h3>Referral History</h3>
        
        {(!stats.referrals || stats.referrals.length === 0) ? (
          <div className="empty-referrals">
            <p>You haven't referred anyone yet. Share your referral code to start earning credits!</p>
          </div>
        ) : (
          <table className="referrals-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Status</th>
                <th>Credits Earned</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {stats.referrals.map((referral, index) => (
                <tr key={index}>
                  <td>{referral.email}</td>
                  <td>
                    <span className={`status-badge ${referral.status}`}>
                      {referral.status === 'pending' ? 'Pending' : 
                       referral.status === 'completed' ? 'Completed' : 
                       referral.status === 'credited' ? 'Credited' : referral.status}
                    </span>
                  </td>
                  <td>{referral.credits_awarded || 0}</td>
                  <td>{formatDate(referral.date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      
      <div className="referral-help">
        <h4>How do referrals work?</h4>
        <ul>
          <li><strong>Pending</strong> - Your friend has signed up but hasn't made their first payment yet.</li>
          <li><strong>Credited</strong> - Your friend has completed their first payment and you've received your referral credits.</li>
        </ul>
        <p>You earn <strong>50 credits</strong> for each friend who signs up and makes their first payment.</p>
      </div>
    </div>
  );
};

export default ReferralStats;