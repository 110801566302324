import React, { useState, useRef, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import DefaultPlaceholders from './Placeholders/DefaultPlaceholders';
import CSVPlaceholders from './Placeholders/CSVPlaceholders';
import CreditsEstimate from './CreditsEstimate';
import { calculateMessageProperties, calculateRequiredCredits } from './utils/messageCalculator';

/**
 * Component for message input with emoji picker and placeholder insertion
 */
const MessageInput = ({ 
  message, 
  setMessage, 
  csvColumns = [],
  userData,
  isAuthorized,
  receivers 
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showDefaultPlaceholders, setShowDefaultPlaceholders] = useState(false);
  const emojiPickerRef = useRef(null);
  
  // Calculate message properties
  const messageProps = calculateMessageProperties(message);
  
  // Calculate required credits
  const requiredCredits = calculateRequiredCredits(message, receivers);
  
  // Handle clicks outside emoji picker to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) && 
          !event.target.classList.contains('emoji-btn')) {
        setShowEmojiPicker(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  // Insert placeholder into message
  const insertPlaceholder = (placeholder) => {
    const textarea = document.getElementById('message');
    const cursorPosition = textarea.selectionStart;
    const textBefore = message.substring(0, cursorPosition);
    const textAfter = message.substring(cursorPosition);
    setMessage(textBefore + placeholder + textAfter);
    
    // Focus back on textarea and set cursor position after the inserted placeholder
    setTimeout(() => {
      textarea.focus();
      textarea.selectionStart = cursorPosition + placeholder.length;
      textarea.selectionEnd = cursorPosition + placeholder.length;
    }, 0);
  };
  
  // Insert emoji into message
  const insertEmoji = (emoji) => {
    const textarea = document.getElementById('message');
    const cursorPosition = textarea.selectionStart;
    const textBefore = message.substring(0, cursorPosition);
    const textAfter = message.substring(cursorPosition);
    
    setMessage(textBefore + emoji + textAfter);
    
    // Focus back on textarea
    setTimeout(() => {
      textarea.focus();
      textarea.selectionStart = cursorPosition + emoji.length;
      textarea.selectionEnd = cursorPosition + emoji.length;
    }, 0);
    
    setShowEmojiPicker(false);
  };
  
  return (
    <div className="form-group">
      <label htmlFor="message">Message:</label>
      <div className="message-input-container">
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here"
          rows="6"
        ></textarea>
        
        <div className="message-tools">
          {/* CSV Column Placeholders */}
          {csvColumns.length > 0 && (
            <CSVPlaceholders 
              csvColumns={csvColumns}
              onInsertPlaceholder={insertPlaceholder}
            />
          )}
          
          {/* Default Placeholders */}
          <div className="placeholders-container">
            <div className="placeholders-label">
              <button 
                type="button"
                className="placeholders-toggle"
                onClick={() => setShowDefaultPlaceholders(!showDefaultPlaceholders)}
              >
                {showDefaultPlaceholders ? '▼' : '►'} Insert dynamic data
              </button>
            </div>
            
            {showDefaultPlaceholders && (
              <DefaultPlaceholders onInsertPlaceholder={insertPlaceholder} />
            )}
          </div>
          
          {/* Emoji Picker */}
          <div className="emoji-container">
            <div className="emoji-toggle">
                <button type="button" className="emoji-btn" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    😊 Add Emoji
                </button>
            </div>
                
                
                {showEmojiPicker && (
                    
                    <EmojiPicker
                        onEmojiClick={(emojiObj) => insertEmoji(emojiObj.emoji)}
                        width={300}
                        height={400}
                        previewConfig={{ showPreview: false }}
                    />
                    
                )}
          </div>
        </div>
      </div>
      
      {/* Message Stats */}
      <div className="message-info">
        {/* Only show the CreditsEstimate when we have actual message content */}
        {isAuthorized && message.trim() ? (
          <CreditsEstimate 
            credits={receivers.trim() ? requiredCredits : messageProps.messageCount} 
            segments={messageProps.messageCount}
            characters={message.length}
            hasSpecialChars={messageProps.hasSpecialChars}
          />
        ) : (
          message.trim() && (
            <small>
              {messageProps.messageCount > 1 
                ? `Will be sent as ${messageProps.messageCount} messages` 
                : 'Single message'}
            </small>
          )
        )}
      </div>
    </div>
  );
};

export default MessageInput;