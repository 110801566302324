import React from 'react';
import { useUser } from '../../contexts/UserContext';
import QuickTopUpModal from '../payment/QuickTopUpModal';

const PaymentModal = ({ onClose, onModalClose }) => {
  const { userData } = useUser();
  
  const handlePaymentComplete = (success, creditsAdded) => {
    onClose();
  };
  
  return (
    <QuickTopUpModal 
      onClose={handlePaymentComplete}
      onModalClose={onModalClose}
    />
  );
};

export default PaymentModal;