import React from 'react';
import BaseModal from './BaseModal';
import Terms from '../../pages/Terms';

const TermsModal = ({ onClose, onModalClose }) => {
  return (
    <BaseModal
      onClose={onClose}
      onModalClose={onModalClose}
    >
      <Terms />
    </BaseModal>
  );
};

export default TermsModal;