import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import BaseModal from './BaseModal';
import referralService from '../../services/referralService';
import ReferralShare from './ReferralShare';
import ReferralStats from './ReferralStats';
import ReferralInvite from './ReferralInvite';

const ReferralModal = ({ onClose, onModalClose }) => {
  const [activeTab, setActiveTab] = useState('share');
  const [referralCode, setReferralCode] = useState('');
  const [referralStats, setReferralStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [sharingText, setSharingText] = useState({});

  // Fetch referral code and stats on mount
  useEffect(() => {
    const fetchReferralData = async () => {
      setLoading(true);
      try {
        // Generate or retrieve referral code
        const codeResponse = await referralService.generateReferralCode();
        if (codeResponse.success && codeResponse.code) {
          setReferralCode(codeResponse.code);
          setSharingText(referralService.formatSharingText(codeResponse.code));
        }
        
        // Get referral stats
        const statsResponse = await referralService.getReferralStats();
        if (statsResponse.success) {
          setReferralStats(statsResponse.stats);
        }
      } catch (error) {
        console.error('Error fetching referral data:', error);
        toast.error('Failed to load referral program data');
      } finally {
        setLoading(false);
      }
    };
    
    fetchReferralData();
  }, []);

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  // Handle copy to clipboard with native Clipboard API
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralUrl);
      setCopied(true);
      toast.success('Referral link copied to clipboard!');
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error('Could not copy to clipboard. Please try again.');
    }
  };

  // Handle email invitation submission
  const handleInviteSubmit = async (emails) => {
    setLoading(true);
    try {
      const response = await referralService.sendReferralInvites(emails);
      if (response.success) {
        toast.success(`Invitations sent to ${response.sent_to.length} contacts!`);
        
        // If there were any failures, notify the user
        if (response.failed && response.failed.length > 0) {
          toast.warning(`Failed to send invitations to ${response.failed.length} contacts.`);
        }
        
        // Refresh referral stats
        const statsResponse = await referralService.getReferralStats();
        if (statsResponse.success) {
          setReferralStats(statsResponse.stats);
        }
        
        // Switch to stats tab
        setActiveTab('stats');
      }
    } catch (error) {
      console.error('Error sending invitations:', error);
      toast.error('Failed to send invitations');
    } finally {
      setLoading(false);
    }
  };
  
  // Generate referral URL
  const referralUrl = sharingText.url || `${window.location.origin}/register?ref=${referralCode}`;
  
  return (
    <BaseModal
      onClose={onClose}
      onModalClose={onModalClose}
      title="Refer Friends & Earn Credits"
      maxWidth="700px"
    >
      <div className="referral-program">
        {loading && !referralCode ? (
          <div className="loading-state">
            <div className="spinner"></div>
            <p>Loading referral program...</p>
          </div>
        ) : (
          <>
            <div className="referral-intro">
              <h3>Invite friends to ToneSMS and earn free credits!</h3>
              <p>For each friend who signs up and makes their first payment, you'll receive <strong>50 credits</strong> and they'll get <strong>25 credits</strong> to start with.</p>
            </div>
            
            <div className="referral-code-box">
              <div className="referral-code-label">Your Referral Code:</div>
              <div className="referral-code">{referralCode}</div>
              <button 
                className="copy-button" 
                onClick={handleCopy}
              >
                {copied ? 'Copied!' : 'Copy Link'}
              </button>
            </div>
            
            <div className="referral-tabs">
              <button 
                className={`tab-button ${activeTab === 'share' ? 'active' : ''}`}
                onClick={() => handleTabChange('share')}
              >
                Share
              </button>
              <button 
                className={`tab-button ${activeTab === 'invite' ? 'active' : ''}`}
                onClick={() => handleTabChange('invite')}
              >
                Invite by Email
              </button>
              <button 
                className={`tab-button ${activeTab === 'stats' ? 'active' : ''}`}
                onClick={() => handleTabChange('stats')}
              >
                My Referrals
              </button>
            </div>
            
            <div className="tab-content">
              {activeTab === 'share' && (
                <ReferralShare 
                  referralCode={referralCode}
                  sharingText={sharingText}
                  referralUrl={referralUrl}
                />
              )}
              
              {activeTab === 'invite' && (
                <ReferralInvite 
                  onSubmit={handleInviteSubmit}
                  loading={loading}
                />
              )}
              
              {activeTab === 'stats' && (
                <ReferralStats 
                  stats={referralStats}
                  loading={loading}
                />
              )}
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
};

export default ReferralModal;